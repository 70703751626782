import React, { useEffect, useState } from "react"
import "./AddOrder.css"
import { Button, Form, Pagination, Table, Nav } from "react-bootstrap"
import Cookies from "js-cookie"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
import { AllProducts } from "./../../Products/AllProducts/AllProducts"
import UserSelectionDropbox from "./Components/UserSelectionDropbox"
import LabSelectionDropbox from "./Components/LabSelectionDropbox"
import ProductsTable from "./Components/ProductsTable"
import SearchInput from "./Components/SearchInput"


const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function AddOrder() {
	const navigate = useNavigate()
	const [allProducts, setAllProducts] = useState([])
	const [allOtherProducts, setAllOtherProducts] = useState([])
	const [userLabType, setUserLabType] = useState("")
	const [selectedProducts, setSelectedProducts] = useState([])
	const [selectedOtherProducts, setSelectedOtherProducts] = useState([])
	const [selectedTags, setSelectedTags] = useState([])
	const [errorMessages, setErrorMessages] = useState("")
	const [otherProductsErrorMessages, setOtherProductsErrorMessages] = useState("")
	const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")
	const [users, setUsers] = useState([])
	const [role, setRole] = useState(Cookies.get("role"))
	const [tabSection, setTabSection] = useState("biochemicals")
	const [startDateOrder, setStartDateOrder] = useState("")
	const [endDateOrder, setEndDateOrder] = useState("")
	const [userExtraOrder, setUserExtraOrder] = useState(false)
	const [onDate, setOnDate] = useState(false)
	const [isQuantityAvailable, setIsQuantityAvailable] = useState(false)
	const [search, setSearch] = useState("")
	const [userCategoriesAssigned, setUserCategoriesAssigned] = useState([])
	const [biochemicalsNotAllowed, setBiochemicalsNotAllowed] = useState(false)
	const [labOrders, setLabOrders] = useState()
	const [userId, setUserId] = useState()
	console.log(allOtherProducts)
	// Vista sede
	const [labsFromUser, setLabsFromUser] = useState([])
	const [labSelected, setLabSelected] = useState("")

	const [isAddOrdenButtonDisabled, setIsAddOrdenButtonDisabled] = useState(false)

	// Paginación de productos
	const [productPageNumber, setProductPageNumber] = useState(0)
	const [otherProductsPageNumber, setOtherProductsPageNumber] = useState(0)
	const [otherProductsStartIndex, setOtherProductsStartIndex] = useState()
	const [otherProductsEndIndex, setOtherProductsEndIndex] = useState()
	const [otherProductsPageCount, setOtherProductsPageCount] = useState()
	const productsPerPage = 10

	const productsStartIndex = productPageNumber * productsPerPage 
	const productsEndIndex = productsStartIndex + productsPerPage
	// Contador de páginas
	const productsPageCount = Math.ceil(allProducts.length / productsPerPage)

	useEffect(() => {
		const otherProductsFiltered = allOtherProducts.filter(otherProduct => userCategoriesAssigned.some(category => category._id === otherProduct.category))
		setOtherProductsStartIndex(otherProductsPageNumber * productsPerPage)
		setOtherProductsEndIndex(otherProductsStartIndex + productsPerPage)
		setOtherProductsPageCount(Math.ceil(otherProductsFiltered.length / productsPerPage))
	}, [allOtherProducts, otherProductsPageNumber, otherProductsStartIndex, userCategoriesAssigned])

	// Handlers Paginacion

	const handleProductsChangePage = selected => {
		setProductPageNumber(selected)
	}

	const handleOtherProductsChangePage = selected => {
		setOtherProductsPageNumber(selected)
	}

	//Verifico fecha en milisegundos para permitir o denegar el pedido
	const currentDate = new Date().getTime()

	// Obtengo los productos asociados al usuario sede
	useEffect(() => {
		const tokenCookies = Cookies.get("token")
		const roleCookies = Cookies.get("role")

			; (async function () {
				try {
					if (role === "sede") {
						const response = await axios.get(`${BACK_APP_URI}/users/myProducts`, {
							headers: {
								Authorization: `${tokenCookies}`,
							},
						})
						setUserLabType(response.data.user.username)
						setStartDateOrder(response.data.user.startDateOrder)
						setEndDateOrder(response.data.user.endDateOrder)
						setBiochemicalsNotAllowed(response.data.user.biochemicalsNotAllowed)
						setLabsFromUser(response.data.user.labs)
						setLabOrders(response.data.user.labOrders)
						setUserId(response.data.user._id)

						// Guarda las categorías habilitadas para pedir del usuario

						const categories = await Promise.all(response.data.user.categoriesAssigned.map(category => getCategoryById(category)))
						setUserCategoriesAssigned(categories)

						if (!tabSection) {
							if (response.data.user.biochemicalsNotAllowed) {
								handleTabChange("various")
							} else {
								handleTabChange("biochemicals")
							}
						}
					} else if (role === "admin") {
						if (!users.length) {

							const users = await axios.get(`${BACK_APP_URI}/users`, {
								headers: {
									Authorization: `${tokenCookies}`,
								},
							})
							setUsers(users.data.users.filter(user => user.username !== "admin" && user.username !== "user" && user.roleName === "sede" && user.labs.length))
						}

						if (userLabType) {
							const userSelected = users.find(user => user.username === userLabType)
							setLabsFromUser(userSelected.labs)
							setLabOrders(userSelected.labOrders)
							const categories = await Promise.all(userSelected.categoriesAssigned.map(category => getCategoryById(category)))
							setUserCategoriesAssigned(categories)
						}

					} else if (role === "logistic") {
						const response = await axios.get(`${BACK_APP_URI}/users/myProducts`, {
							headers: {
								Authorization: `${tokenCookies}`,
							},
						})
						setUserLabType(response.data.user.username)
						setLabsFromUser(response.data.user.labs)
					}

					if (labSelected) {
						const labSelectedData = labsFromUser.find(lab => lab._id === labSelected)

						// Filtro por nombre productos y etiquetas BIOQUIMICOS
						const filteredProductTags = labSelectedData.tags.filter(tag => tag.name?.toLowerCase().includes(search.toLowerCase()) && tag.isProducts).map(tag => ({ ...tag, isTag: true }))
						const filteredProducts = labSelectedData.products.filter(product => product.name?.toLowerCase().includes(search.toLowerCase()))
						setAllProducts([...filteredProductTags, ...filteredProducts])

						// Filtro por nombre productos y etiquetas VARIOS
						const filteredOtherProductTags = labSelectedData.tags.filter(tag => tag.name?.toLowerCase().includes(search.toLowerCase()) && !tag.isProducts).map(tag => ({ ...tag, isTag: true }))
						const filteredOtherProducts = labSelectedData.otherProducts.filter(product => product.name?.toLowerCase().includes(search.toLowerCase()))
						setAllOtherProducts([...filteredOtherProductTags, ...filteredOtherProducts])
					}

					// if (roleCookies === "logistic") {
					// 	setRole("admin")
					// } else {
					// 	setRole(roleCookies)
					// }
				} catch (error) {
					console.log("Error al obtener los productos:", error)
				}
			})()
	}, [role, search, labSelected, userLabType])

	// Obtener las categorias
	const getCategoryById = async id => {
		const response = await axios.get(`${BACK_APP_URI}/categories/${id}`)

		return response.data
	}

	// Manejar productos seleccionados
	const handleAddOrRemoveItems = (id, isTag, isProducts) => {
		if (isProducts) {
			if (selectedProducts.some(p => p.id === id)) {
				setSelectedProducts(prev => prev.filter(p => p.id !== id))
			} else {
				setSelectedProducts(prev => [...prev, { id, quantityRequested: "1", isTag, isProducts }])
			}
		} else {
			if (selectedOtherProducts.some(p => p.id === id)) {
				setSelectedOtherProducts(selectedOtherProducts.filter(p => p.id !== id))
			} else {
				setSelectedOtherProducts([...selectedOtherProducts, { id, quantityRequested: "1", isTag, isProducts }])
			}
		}

	}

	const handleEditItems = (id, quantity, isProducts) => {
		if (isProducts) {
			setSelectedProducts(prev => prev.map(product => {
				if (product.id === id) {
					return { ...product, quantityRequested: quantity }
				} else {
					return product
				}
			}))
		} else {
			setSelectedOtherProducts(prev => prev.map(product => {
				if (product.id === id) {
					return { ...product, quantityRequested: quantity }
				} else {
					return product
				}
			}))
		}
	}

	const createOrder = async ({ isAdmin }) => {
		setIsAddOrdenButtonDisabled(true)

		const productsToOrder = selectedProducts.filter(product => !product.isTag)
		const otherProductsToOrder = selectedOtherProducts.filter(product => !product.isTag)
		const productTagsToOrder = selectedProducts.filter(product => product.isTag)
		const otherProductTagsToOrder = selectedOtherProducts.filter(product => product.isTag)

		await axios({
			method: "post",
			url: `${BACK_APP_URI}/pedidos/add`,
			data: {
				username: userLabType,
				products: productsToOrder,
				otherProducts: otherProductsToOrder,
				productTags: productTagsToOrder,
				otherProductTags: otherProductTagsToOrder,
				isFromAdmin: isAdmin ? true : false,
				labOrigin: labSelected,
			},
		})
			.then(data => {
				setVariable(true)
				setErrorBool(false)
				setAvisomsg("Pedido cargado con éxito!")
				setTimeout(() => {
					setVariable(false)
					navigate("/orders")
				}, 1200)
			})
			.catch(error => {
				// console.log(error)
				setErrorBool(true)
				setVariable(true)
				setAvisomsg(error.response.data.msg)
				setTimeout(() => {
					setVariable(false)
					setIsAddOrdenButtonDisabled(false)
				}, 3200)
			})

		if (!isAdmin && selectedOtherProducts.length > 0) {
			updateUserLabOrders(userId, labSelected)
		}
	}

	const updateUserLabOrders = async (userId, lab) => {
		try {
			await axios.post(`${BACK_APP_URI}/users/editUserLabOrders/${userId}`, { userId: userId, lab: lab })

		} catch (error) {
			alert("Error al actualizar el número de orden. Consulta la consola para más detalles.")
		}
	}

	const handleTabChange = section => {
		setTabSection(section)
	}

	// Fechas objetos a milisegundos

	const startDateTime = new Date(startDateOrder).getTime()
	const endDateTime = new Date(endDateOrder).getTime()

	// Condición para fecha de pedido
	useEffect(() => {

		if (role !== "sede" || (currentDate >= startDateTime && currentDate <= endDateTime) || userExtraOrder === true) {
			setOnDate(true)
		}
		// Verificar si el laboratorio seleccionado tiene un número de orden válido
		if (labSelected !== "" && labOrders) {
			const labWithValidOrder = labOrders.find(labObj => labObj.lab.toString() === labSelected)
			if (labWithValidOrder && labWithValidOrder.quantityAvailable > 0) {
				setIsQuantityAvailable(true)
			} else {
				setIsQuantityAvailable(false)
			}

			if(labWithValidOrder) {
				setUserExtraOrder(labWithValidOrder.extraOrder)
			}
		}

	}, [currentDate, startDateTime, endDateTime, userExtraOrder, onDate, labSelected, labOrders])

	const handleLabSelectedChange = labId => {
		setLabSelected(labId)

		setSelectedProducts([])

		setSelectedOtherProducts([])
		setSearch("")
	}

	const handleUserLabTypeChange = userId => {
		setUserLabType(userId)
		handleLabSelectedChange("")
	}

	return (
		<>
			<div className="main-container">
				<div className="header--container">
					<div className="order--header-cont">
						<div className="order--header-row">
							<h1 className="order--title">Carga pedido</h1>
							
						</div>
						<div className="order-row-select">
							{/* Selección de usuario si es admin */}
							{role === "admin" && (
								<UserSelectionDropbox
									handleUserLabTypeChange={handleUserLabTypeChange}
									userLabType={userLabType}
									users={users}
								/>
							)}

							{/* Selección de laboratorio */}
							{(role === "sede" || role === "logistic" || userLabType) && (
								<LabSelectionDropbox
									labSelected={labSelected}
									labsFromUser={labsFromUser}
									handleLabSelectedChange={handleLabSelectedChange}
								/>

							)}

						</div>
					</div>
				</div>
				<div className="card--container">
					<div className="header-table-order">
						<Nav className="nav-tabs">
							<Nav.Item>
								<Nav.Link
									eventKey="biochemicals"
									onClick={() => handleTabChange("biochemicals")}
									active={tabSection === "biochemicals"}
									disabled={biochemicalsNotAllowed}
								>
									Bioquímicos
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									eventKey="various"
									onClick={() => handleTabChange("various")}
									active={tabSection === "various"}
								>
									Varios
								</Nav.Link>
							</Nav.Item>
						</Nav>
						<SearchInput
                                search={search}
								onChange={e => {
									setSearch(e.target.value)
									handleProductsChangePage(0)
									handleOtherProductsChangePage(0)
								}}
							/>

						
					</div>
					{/* Tab y filtro por nombre */}


					<div className="table-container-new">
						<div className="table-striped table-bordered table-hover">
							{/* Bioquímicos */}
							{tabSection === "biochemicals" && !(role === "sede" && biochemicalsNotAllowed) ? (
								allProducts.length > 0 ? (
									<ProductsTable
										allItems={allProducts}
										errorMessages={errorMessages}
										handleAddOrRemoveItems={handleAddOrRemoveItems}
										handleEditItems={handleEditItems}
										itemsStartIndex={productsStartIndex}
										itemsEndIndex={productsEndIndex}
										selectedItems={selectedProducts}
										role={role}
										tabSection={tabSection}
										isProduct
									/>
								) : (
									<div style={{ textAlign: 'center' }}>
										No hay productos bioquímicos asignados a este laboratorio.
									</div>
								)
							) : // Productos 'varios'
								tabSection === "various" && (role !== "sede" || ((isQuantityAvailable || userExtraOrder) && onDate)) ? (
									allOtherProducts.length > 0 ? (
										<>
											{userExtraOrder && <h4>Pedido extra</h4>}

											<ProductsTable
												allItems={allOtherProducts}
												errorMessages={otherProductsErrorMessages}
												handleAddOrRemoveItems={handleAddOrRemoveItems}
												handleEditItems={handleEditItems}
												itemsStartIndex={otherProductsStartIndex}
												itemsEndIndex={otherProductsEndIndex}
												selectedItems={selectedOtherProducts}
												role={role}
												categoriesAssigned={userCategoriesAssigned}
											/>
										</>
									) : (
										<div style={{ textAlign: 'center' }}>No hay productos de tipo varios asignados a este laboratorio.</div>
									)
								) : (
									<>
										{!onDate && <p style={{ textAlign: 'center' }}>Actualmente se encuentra fuera de fecha para realizar pedidos.</p>}
										{!isQuantityAvailable && <p style={{ textAlign: 'center' }}>No es posible realizar un pedido en este momento.</p>}
									</>
								)}
						</div>
					</div>

					{/* Paginación */}
					<div className="pagination-order pagination-all-tag">
						{tabSection === "biochemicals" ? (
							<Pagination>
								<Pagination.Prev
									onClick={() => handleProductsChangePage(productPageNumber > 0 ? productPageNumber - 1 : 0)}
									disabled={productPageNumber === 0}
								/>
								{Array.from({ length: productsPageCount }).map((_, index) => (
									<Pagination.Item
										className="pagination-item"
										key={index}
										active={index === productPageNumber}
										onClick={() => handleProductsChangePage(index)}
									>
										{index + 1}
									</Pagination.Item>
								))}
								<Pagination.Next
									onClick={() => handleProductsChangePage(productPageNumber < productsPageCount - 1 ? productPageNumber + 1 : productsPageCount - 1)}
									disabled={productPageNumber === productsPageCount - 1}
								/>
							</Pagination>
						) : tabSection === "various" && (role !== "sede" || ((isQuantityAvailable || userExtraOrder) && onDate)) && (
								<Pagination>
									<Pagination.Prev
										onClick={() => handleOtherProductsChangePage(otherProductsPageNumber > 0 ? otherProductsPageNumber - 1 : 0)}
										disabled={otherProductsPageNumber === 0}
									/>
									{Array.from({ length: otherProductsPageCount }).map((_, index) => (
										<Pagination.Item
											className="pagination-item"
											key={index}
											active={index === otherProductsPageNumber}
											onClick={() => handleOtherProductsChangePage(index)}
										>
											{index + 1}
										</Pagination.Item>
									))}
									<Pagination.Next
										onClick={() => handleOtherProductsChangePage(otherProductsPageNumber < otherProductsPageCount - 1 ? otherProductsPageNumber + 1 : otherProductsPageCount - 1)}
										disabled={otherProductsPageNumber === otherProductsPageCount - 1}
									/>
								</Pagination>
							)
						}
					</div>

					{/* Botones */}
					<div className="content-card-order">
						<Button
							className="button-carga-order"
							children="Generar Pedido"
							onClick={() => createOrder({ isAdmin: role === "sede" ? false : true })}
							disabled={isAddOrdenButtonDisabled || labSelected === "" || (!selectedProducts.length && !selectedOtherProducts.length)}
						/>
					</div>
				</div>
			</div>
			{/* Modal de estado */}
			{variable && (
				<AnimacionSuccessful
					errorBool={errorBool}
					avisomsg={avisomsg}
				/>
			)}
		</>
	)
}
