import React, { useEffect, useState, useMemo } from "react"
import "./ExportOrderModal.css"
import { Modal, Form, Button, Dropdown, FormCheck } from "react-bootstrap"
import { DateRangePicker } from "react-dates"
import moment from "moment"
import Cookies from "js-cookie"
import axios from "axios"
import { utils, writeFile } from "xlsx"
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink } from "@react-pdf/renderer"
import innovis_adobe_express from '../../Assets/Img/innovis_adobe_express.png';



const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function ExportOrderModal({ show, onHide, labs }) {
	const [ordersToExport, setOrdersToExport] = useState([]) // Guarda las ordenes con todos sus datos
	const [filteredLab, setFilteredLab] = useState("") // Laboratorio seleccionado para filtrar
	const [productsToExport, setProductsToExport] = useState([]) // Guarda los productos encontrados en las ordenes filtradas
	const [providerNames, setProvidersNames] = useState([])
	const [showTagsOnly, setShowTagsOnly] = useState(false);
	const [stateSelected, setStateSelected] = useState("Pendiente")
	const [groupedProducts, setGroupedProducts] = useState([]);
	const [tagProducts, setTagProducts] = useState([]);

	const [openDropdownStateSelector, setOpenDropdownStateSelector] = useState(true)

	//---------------Date Range Picker------------------------------------------------

	moment.locale("es") // Establece el idioma para Moment.js
	const [startDate, setStartDate] = useState(moment().subtract(7, "days"))
	const [endDate, setEndDate] = useState(moment())
	const [focusedInput, setFocusedInput] = useState(null)

	const startDateTime = new Date(startDate).getTime()
	const endDateTime = new Date(endDate).getTime()

	const formatDate = (date) => (date ? moment(date).format("DD/MM/YYYY") : "-");



	const handleDateRangeChange = ({ startDate, endDate }) => {
		setStartDate(startDate)
		setEndDate(endDate)
	}

	const isOutsideRange = day => {
		return false // Habilita todos los días
	}



	useEffect(() => {
		; (async () => {
			const token = Cookies.get("token")
			const ordersResponse = await axios.get(`${BACK_APP_URI}/pedidos`, {
				headers: {
					Authorization: `${token}`,
				},
			})

			const responseProvider = await axios({
				method: "get",
				url: `${BACK_APP_URI}/proveedor`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const tagResponse = await axios.get(`${BACK_APP_URI}/tags`, {
				headers: {
					Authorization: `${token}`,
				},
			});

			setTagProducts(tagResponse.data.tags); // Asegúrate de que las tags se guarden aquí



			setTagProducts(tagResponse.data.tags || []);

			const providers = responseProvider.data.provider.map(provider => {
				return {
					id: provider._id,
					name: provider.name,
				}
			})

			setProvidersNames(providers)

			// Filtra si fue enviado o recibido
			const allOrders = ordersResponse.data.orders.filter(order => {

				if (stateSelected === "Pendiente") {
					return !order.hasOwnProperty("state")
				}

				return order?.state === stateSelected
			}).filter(order => {
				return labs.some(lab => lab?.name === order?.labOrigin?.name)
			})

			// Filtra por fecha seleccionada
			const ordersBetweenDates = allOrders.filter(order => {
				return new Date(order.createdAt).getTime() >= startDateTime && new Date(order.createdAt).getTime() <= endDateTime
			})

			// Filtra por laboratorio seleccionado (Opcional)
			if (!filteredLab == "") {
				const ordersFromLab = ordersBetweenDates.filter(order => order.labOrigin.name == filteredLab)

				setOrdersToExport(ordersFromLab)
			} else {
				setOrdersToExport(ordersBetweenDates)
			}
		})()
	}, [filteredLab, startDateTime, endDateTime, stateSelected, labs])



	// Se encarga de los productos en las ordenes filtradas
	useEffect(() => {
		let productsArray = [];

		ordersToExport.forEach(order => {
			// productos bioquímicos
			if (order?.products?.length > 0) {
				order.products.forEach(product => {
					if (!product?.data) return;

					const relatedTag = tagProducts.find(tag =>
						tag.products.some(p => p.name === product.data.name)
					);

					const quantityToDisplay = product.quantityToSend ?? product.quantityRequested ?? 0;

					productsArray.push({
						id: product.data._id || 'N/A',
						orderCreatedAt: formatDate(order.createdAt),
						orderDeliveredAt: formatDate(order.deliveredAt),
						lab: order.labOrigin?.name || 'N/A',
						code: product.data.code || 'N/A',
						productName: product.data.name || 'N/A',
						brand: product.data.brand || 'N/A',
						quantity: quantityToDisplay,
						provider: product.data.provider?.name || 'N/A',
						isTag: !!relatedTag,
						tagName: relatedTag?.name || null,
					});
				});
			}

			// productos varios 
			if (order?.otherProducts?.length > 0) {
				order.otherProducts.forEach(otherProduct => {
					if (!otherProduct?.data) return;

					const relatedTag = tagProducts.find(tag =>
						tag.otherProducts.some(p => p._id === otherProduct.data._id)
					);

					const quantityToDisplay = otherProduct.quantityToSend ?? otherProduct.quantityRequested ?? 0;

					productsArray.push({
						id: otherProduct.data._id || 'N/A',
						orderCreatedAt: formatDate(order.createdAt),
						orderDeliveredAt: formatDate(order.deliveredAt),
						lab: order.labOrigin?.name || 'N/A',
						code: otherProduct.data.code || 'N/A',
						productName: showTagsOnly && relatedTag ? relatedTag.name : otherProduct.data.name || "Producto sin tag",
						brand: "-",
						quantity: quantityToDisplay,
						provider: "-",
						isTag: !!relatedTag,
						tagName: relatedTag?.name || "-",
					});

				});
			}


			if (order?.tags?.length > 0) {
				order.tags.forEach(tag => {
					let totalQuantity = 0;

					//suma la cantidad de los productos que tienen tag
					if (tag.products?.length > 0) {
						totalQuantity = tag.products.reduce((acc, tagProduct) => {
							const productInOrder = order.products?.find(
								p => p.data._id === tagProduct._id
							);
							return acc + (productInOrder?.quantityRequested || 0);
						}, 0);
					}

					productsArray.push({
						id: tag.data._id,
						orderCreatedAt: formatDate(order.createdAt),
						orderDeliveredAt: formatDate(order.deliveredAt),
						lab: order.labOrigin?.name,
						code: "-",
						productName: showTagsOnly ? tag.data.name : tag.data.name || "Etiqueta de Productos",
						brand: "-",
						quantity: totalQuantity || 0,
						provider: "-",
						isTag: true,
						tagName: tag.data.name,
					});
				});
			}
		});

		// Evitar duplicados
		const uniqueProductsArray = Array.from(new Set(productsArray.map(p => JSON.stringify(p))))
			.map(p => JSON.parse(p));

		setProductsToExport(uniqueProductsArray);
	}, [ordersToExport, tagProducts]);





	function groupProductsByTags(products, tags, showTagsOnly) {
		const grouped = [];
		const tagMap = new Map();

		products.forEach((product) => {
			if (product.isTag && product.tagName && product.tagName !== "-") {
				if (!tagMap.has(product.tagName)) {
					tagMap.set(product.tagName, {
						...product,
						displayName: showTagsOnly ? product.tagName : product.productName,
						groupedProducts: []
					});
				}
			} else if (product.tagName && tagMap.has(product.tagName)) {
				tagMap.get(product.tagName).groupedProducts.push(product);
			} else {
				grouped.push({
					...product,
					displayName: product.productName,
				});
			}
		});


		if (showTagsOnly) {
			// Mostrar solo los productos agrupados por tags
			return Array.from(tagMap.values());
		}

		// Mostrar todos los productos, incluyendo los no agrupados
		return [...Array.from(tagMap.values()), ...grouped];
	}

	useEffect(() => {
		const grouped = productsToExport.map(product => {
			return {
				...product,
				displayName: showTagsOnly && product.tagName !== "-" ? product.tagName : product.productName, // Mostrar tagName si el checkbox está activo
			};
		});

		setGroupedProducts(grouped);
	}, [productsToExport, tagProducts, showTagsOnly]);









	// Acción descarga del excel
	const exportOrdersExcel = () => {
		const ordersData = productsToExport.map(product => ({
			Sede: product.lab,
			Código: product.code,
			Nombre: product.productName,
			Cantidad: product.quantity,
			Marca: product.brand,
			Proveedor: product.provider,
			"Fecha realizado": product.orderCreatedAt,
			"Fecha entregado": product.orderDeliveredAt,
			Estado: stateSelected
		}))

		const worksheet = utils.json_to_sheet(ordersData)

		// Crear el objeto workbook y asignar el worksheet
		const workbook = utils.book_new()
		utils.book_append_sheet(workbook, worksheet, "Pedidos de sede")

		// Guardar el archivo Excel
		writeFile(workbook, `Lista_Pedidos_Sedes.xlsx`)
	}


	// Botón con la descarga del pdf
	const exportOrdersPDFButton = () => {
		const styles = StyleSheet.create({


			body: {
				paddingTop: 35,
				paddingBottom: 65,
				alignItems: "center",
			},
			header: {
				width: '90%',
				display: 'flex',
				flexDirection: "row",
				justifyContent: "space-between",
				marginBottom: 16,
				padding: '30px',
				alignItems: 'flex-start',
				backgroundColor: '#f4fbfa',
				borderRadius: '10px',
			},
			highlight: {
				color: "#fff",
				fontSize: 9,
				backgroundColor: "rgb(47, 161, 161)",
				padding: 5,
				paddingLeft: 10,
				paddingRight: 10,
				borderRadius: '3px',
				marginBottom: '5px',
				textTransform: 'uppercase',
			},
			membrete: {
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				gap: '5px',
			},
			membreteLogo: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
			logo: {
				width: 80,
				height: 60,
			},
			infoLogo: {
				fontSize: 10,
				fontWeight: 'normal',
				padding: 5,

			},

			title: {
				fontSize: 28,
				fontWeight: 'extrabold',
				color: '#056a6c'
			},
			date: {
				textAlign: "center",
				fontSize: 10,
				fontWeight: 'bold',
			},
			table: {
				display: "table",
				width: "90%",
				borderRightWidth: 1,
				borderBottomWidth: 1,
				borderLeftWidth: 1,
				borderTopWidth: 1,
				borderRadius: '10px',
				borderColor: '#d5dbdb',
				textAlign: 'start',
			},
			tableRow: {
				flexDirection: "row",
			},
			tableRowTitle: {
				borderRadius: '5px',
				backgroundColor: '#029598',
				padding: '8px',
				flexDirection: "row",
				borderBottom: '0px',


			},
			tableCol: {

				width: "50%",
				borderStyle: 'solid',
				borderColor: '#d5dbdb',
			},

			tableColLeft: {
				borderRightWidth: 1,
			},

			tableCell: {
				textAlign: 'left',
				margin: "auto",
				marginTop: 4,
				marginBottom: 4,
				fontSize: 8,
				padding: 5,
			},
			tableTitle: {
				margin: "auto",
				marginTop: 2,
				marginBottom: 2,
				fontSize: 10,
				color: "white",
				padding: 5,
				textAlign: 'left',

			},
			footer: {
				position: "absolute",
				bottom: 20,
				left: 0,
				right: 0,
				textAlign: "center",
				fontSize: 9,
				padding: 10,
				borderTopWidth: 1,
				borderColor: '#cce8e8',
				color: '#056a6c',
			},
		})





		const Table = () => (
			<Document>
				<Page
					size="A4"
					style={styles.body}
				>
					<View style={styles.header}>
						<View style={styles.membrete}>
							<Text style={styles.title}>Pedidos sedes</Text>
							<Text style={styles.highlight}>
								{`PEDIDO: ${stateSelected.toUpperCase()}`}
							</Text>

							<Text style={styles.date}>Fecha: {new Date().toLocaleDateString()}</Text>
							<View style={styles.spacer} />
						</View>
						<View style={styles.membreteLogo}>
							<Image src={innovis_adobe_express} style={styles.logo} />
							<Text style={styles.infoLogo}>Laboratorio bioquímico integral</Text>

						</View>
					</View>
					<View style={[styles.table, { display: 'flex', justifyContent: 'flex-start', textAlign: 'start' }]}>
						<View style={styles.tableRowTitle}>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Sede</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Código</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Nombre</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Cantidad</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Marca</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Proveedor</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Creación</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Entrega</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Estado</Text>
							</View>
						</View>
						{groupedProducts.map((product, index) => {



							return (
								<View
									style={[styles.tableRow, { borderTopWidth: 1, borderTopColor: "#d5dbdb", borderTopStyle: "solid" }]}
									key={product.id || `product-${index}`}
								>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.lab || "Sin laboratorio"}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.code || "N/A"}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.displayName}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.quantity}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.brand}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.provider}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.orderCreatedAt}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.orderDeliveredAt}</Text>
									</View>
									<View style={styles.tableCol}>
										<Text style={styles.tableCell}>{stateSelected}</Text>
									</View>
								</View>
							);
						})}
					</View>
					<View style={styles.footer}>
						<Text>INNOVIS | LABORATORIOS BIOQUÍMICOS</Text>
					</View>
				</Page>
			</Document>
		);

		return (
			<PDFDownloadLink
				document={<Table />}
				fileName="Lista_Pedidos_Sedes.pdf"
				style={{
					color: "#ffffff",
					textDecoration: "none",
				}}
			>
				{({ blob, url, loading, error }) => "Descargar PDF"}
			</PDFDownloadLink>
		)
	}

	return (
		<Modal className="modal-export-header"
			show={show}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={onHide}
		>
			<Modal.Header className="modal-export-cont" closeButton>
				<Modal.Title className="modal-export" id="contained-modal-title-vcenter">Exportar pedidos</Modal.Title>
			</Modal.Header>
			<div className="modal-body-cont">
				<Modal.Body className="modal-export-body">
					<div className="mb-1">
						<DateRangePicker
							startDate={startDate}
							startDateId="your_unique_start_date_id"
							endDate={endDate}
							endDateId="your_unique_end_date_id"
							onDatesChange={handleDateRangeChange}
							focusedInput={focusedInput}
							onFocusChange={focusedInput => setFocusedInput(focusedInput)}
							small
							displayFormat="DD/MM/YY" // Define el formato de visualización
							monthFormat="MMMM YYYY"
							weekDayFormat="dd"
							isOutsideRange={isOutsideRange}
						/>
					</div>



					<div className="modal-tabla">
						{openDropdownStateSelector &&
							["Pendiente", 'Aceptado', 'Aceptado sin Stock', 'Rechazado', 'Enviado', 'Recibido'].map(state => {
								return (
									<button
										size="sm"
										className={`dropdown-button ${stateSelected === state && "dropdown-button-selected"}`}
										onClick={() => setStateSelected(state)}
									>
										{state}
									</button>
								)
							})}
					</div>

					<Form.Select
						className="form-modal-export"
						onChange={e => {
							setFilteredLab(e.target.value)
						}}
					>
						<option value="">Todas las sedes</option>
						{labs.map(lab => {
							return (
								<option
									key={lab._id}
									value={lab?.name}
								>
									{lab?.name}
								</option>
							)
						})}
					</Form.Select>
					<Form.Check
						type="checkbox"
						label="Generar PDF para usuario"
						checked={showTagsOnly}
						onChange={(e) => setShowTagsOnly(e.target.checked)}
					/>

					<div>
						Se encontraron <b>{ordersToExport.length}</b> pedidos para exportar.
					</div>


				</Modal.Body>
			</div>
			<Modal.Footer>
				<div className="btn-modal-export">
					{/* Botón para Excel */}
					<Button
						style={{ width: "10rem", marginRight: "1rem" }}
						className="btn-modal-order"
						onClick={exportOrdersExcel}
						disabled={!ordersToExport.length > 0}
					>
						Descargar Excel
					</Button>

					{/* Botón para el PDF  */}
					<Button
						style={{ width: "10rem", marginRight: "1rem" }}
						className="btn-modal-order"
						disabled={!ordersToExport.length > 0}

					>
						{exportOrdersPDFButton()}

					</Button>




				</div>
			</Modal.Footer>

		</Modal>

	)

}
