import React, { useEffect, useState } from "react"
import { Button, Form, Pagination, Table, Nav } from "react-bootstrap"
import Cookies from "js-cookie"
import axios from "axios"
import { useParams, useNavigate } from "react-router-dom"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
import "./EditOrder.css"
import delyIcon from "./../../../Assets/Img/delivery-truck-4439.svg"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { PDFDownloadLink } from "@react-pdf/renderer"
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AddProductToOrderModal from "./Components/AddProductToOrderModal"
import OrderPDF from "./SendedProductsPDF"
import ProductsTable from "./Components/ProductsTable"
import PendingProductsTable from "./Components/PendingProductsTable"
import { ALL_ORDER_STATES } from "../const"
import { TextField } from "@mui/material"
import { convertQuantityToSend, roundNumber } from "./utils"
import PaginationComponent from "./Components/PaginationComponent"

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function EditOrder() {
	const navigate = useNavigate()
	const { id } = useParams()
	const [role, setRole] = useState("")
	const [orderData, setOrderData] = useState([])
	const [orderProducts, setOrderProducts] = useState([])
	const [allProducts, setAllProducts] = useState([])
	const [orderOtherProducts, setOrderOtherProducts] = useState([])
	const [allOtherProducts, setAllOtherProducts] = useState([])
	const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")
	const [tabSection, setTabSection] = useState("") // biochemicals various
	const [search, setSearch] = useState("")
	const [pendingProducts, setPendingProducts] = useState([])
	const [pendingOtherProducts, setPendingOtherProducts] = useState([])
	const [startDateOrder, setStartDateOrder] = useState("")
	const [endDateOrder, setEndDateOrder] = useState("")
	const [offDate, setOffDate] = useState(true)
	const [comment, setComment] = useState("")
	const [texto, setTexto] = useState("")
	const maxCaracteres = 300 // Cambia la cantidad de caracteres max de Observaciones
	const [verifyComment, setVerifyComment] = useState(false)
	const [allLots, setAllLots] = useState()
	const [originalTags, setOriginalTags] = useState([]); // Estado para etiquetas iniciales
	const [originalOtherTags, setOriginalOtherTags] = useState([]); 
	const [showAddProductModal, setShowAddProductModal] = useState(false)
	
	// Paginación de productos
	const [productPageNumber, setProductPageNumber] = useState(0)
	const productsPerPage = 6
	const productsStartIndex = productPageNumber * productsPerPage
	const productsEndIndex = productsStartIndex + productsPerPage
	const productsPageCount = orderProducts ? Math.ceil(orderProducts?.length / productsPerPage) : 0
	const otherProductsPageCount = orderOtherProducts ? Math.ceil(orderOtherProducts?.length / productsPerPage) : 0

	// Paginación de productos pendientes
	const [pendingProductPageNumber, setPendingProductPageNumber] = useState(0)
	const pendingProductsPerPage = 6
	const pendingProductsStartIndex = pendingProductPageNumber * pendingProductsPerPage
	const pendingProductsEndIndex = pendingProductsStartIndex + pendingProductsPerPage
	const pendingProductsPageCount = pendingProducts ? Math.ceil(pendingProducts?.length / pendingProductsPerPage) : 0
	const pendingOtherProductsPageCount = pendingOtherProducts ? Math.ceil(pendingOtherProducts?.length / pendingProductsPerPage) : 0

	const currentDate = new Date().getTime()

	const startDateTime = new Date(startDateOrder).getTime()
	const endDateTime = new Date(endDateOrder).getTime()

	// Condición para fecha de pedido
	useEffect(() => {
		// eslint-disable-next-line no-mixed-operators
		if (currentDate >= startDateTime && currentDate <= endDateTime) {
			setOffDate(false)
		}

		//console.log("onDate? ", onDate);
		//console.log("Sede? ", role)
	}, [currentDate, startDateTime, endDateTime])

	function productAndTagInArray(productArr, tagArr, isProducts) {
		return [
			...productArr,
			...tagArr
				.filter(tag => (isProducts ? tag.data.isProducts : !tag.data.isProducts))
				.map(tag => {
					return { ...tag, isTag: true }
				}),
		]
	}

	function regroupProductsByTags(products) {
		const groupedTags = [];
		const untaggedProducts = []; // Productos sin etiqueta
	
		products.forEach((product) => {
			const tagName = product.data?.tag?.name || "-";
	
			if (tagName === "-") {
				
				untaggedProducts.push({
					...product, 
					data: {
						...product.data,
						name: product.data.name || "Producto sin nombre", 
					},
					quantityRequested: product.quantityRequested || 0, 
					quantityToSend: product.quantityToSend || 0, 
				});
			} else {
				// Producto con etiqueta
				const existingTag = groupedTags.find((tag) => tag.name === tagName);
	
				const productData = {
					_id: product._id,
					quantityToSend: product.quantityToSend || 0,
					measure: product.data.unitType?.name || "",
					weightOrVolume: product.data.weightOrVolume || "",
				};
	
				if (existingTag) {
					existingTag.products.push(productData);
					existingTag.quantityRequested += product.quantityRequested || 0;
				} else {
					groupedTags.push({
						name: tagName,
						products: [productData],
						quantityRequested: product.quantityRequested || 0,
					});
				}
			}
		});
	
		return { groupedTags, untaggedProducts };
	}

	//Mostrar etiquetas rol sede
	useEffect(() => {
		if (role === "sede" && !orderData.isFromBiochemicalRole) {
			const { groupedTags, untaggedProducts } = regroupProductsByTags(orderData.products || []);
			const { groupedTags: otherGroupedTags, untaggedProducts: otherUntaggedProducts } = regroupProductsByTags(orderData.otherProducts || []);
			
	
			// Combinar etiquetas y productos sin etiquetas
			setOriginalTags([...groupedTags, ...untaggedProducts]);
			setOriginalOtherTags([...otherGroupedTags, ...otherUntaggedProducts]);
		}
	}, [orderData, role]);
	
	

	  
	// Información de orden y usuario
	useEffect(() => {
		; (async () => {
			try {
				const tokenCookies = Cookies.get("token")
				const roleCookies = Cookies.get("role")

				if (roleCookies === "logistic") {
					setRole("admin")
				} else {
					setRole(roleCookies)
				}

				// Información de la orden
				const orderResponse = await axios.get(`${BACK_APP_URI}/pedidos/${id}`, {
					headers: {
						Authorization: `${tokenCookies}`,
					},
				})
				setOrderData(orderResponse.data)

				const pendingProductsArr = productAndTagInArray(orderResponse.data.pendingProducts, orderResponse.data.pendingTags, true)
				const pendingOtherProductsArr = productAndTagInArray(orderResponse.data.pendingOtherProducts, orderResponse.data.pendingTags, false)

				setPendingProducts(pendingProductsArr)
				setPendingOtherProducts(pendingOtherProductsArr)

				// Ver que tipo de productos hay en la orden y configurar la navegación
				if (productAndTagInArray(orderResponse.data.products, orderResponse.data.tags, true).length || pendingProductsArr.length) {
					setTabSection("biochemicals")
				} else {
					setTabSection("various")
				}

				// Información del usuario
				if (roleCookies === "sede" && orderResponse.data.status !== undefined) {
					const userResponse = await axios.get(`${BACK_APP_URI}/users/myProducts`, {
						headers: {
							Authorization: `${tokenCookies}`,
						},
					})
					setStartDateOrder(userResponse.data.user.startDateOrder)
					setEndDateOrder(userResponse.data.user.endDateOrder)
				}
			} catch (error) {
				console.log("Error al obtener información de la orden:", error)
			}
		})()
	}, [])
	// Buscador
	useEffect(() => {
		if (!orderData) return

		const products = orderData.products || []
		const otherProducts = orderData.otherProducts || []
		const tags = orderData.tags || []

		if (search === "" || !search) {
			setOrderProducts(productAndTagInArray(products, tags, true).filter(product => !pendingProducts.some(pendingProduct => pendingProduct._id === product._id)))

			setOrderOtherProducts(productAndTagInArray(otherProducts, tags, false).filter(product => !pendingOtherProducts.some(pendingProduct => pendingProduct._id === product._id)))
			return
		}

		const filteredProducts = orderProducts.filter(product => product.data.name.toLowerCase().includes(search.toLowerCase()))

		const filteredOtherProducts = orderOtherProducts.filter(product =>
			product.data.name.toLowerCase().includes(search.toLowerCase())
		)

		setOrderProducts(filteredProducts)
		setOrderOtherProducts(filteredOtherProducts)
	}, [search, orderData])

	//Manejar productos seleccionados
	const handlePendingProductsCheckbox = id => {
		if (role !== "sede") {
			if (orderProducts.some(product => product.data._id === id)) {
				setPendingProducts(prev => [...prev, orderProducts.find(product => product.data._id === id)])
				setOrderProducts(prev => prev.filter(product => product.data._id !== id))
			} else {
				setOrderProducts(prev => [...prev, pendingProducts.find(product => product.data._id === id)])
				setPendingProducts(prev => prev.filter(product => product.data._id !== id))
			}
		}
	}
	const handlePendingOtherProductsCheckbox = id => {
		if (role !== "sede") {
			if (orderOtherProducts.some(product => product.data._id === id)) {
				setPendingOtherProducts(prev => [...prev, orderOtherProducts.find(product => product.data._id === id)])
				setOrderOtherProducts(prev => prev.filter(product => product.data._id !== id))
			} else {
				setOrderOtherProducts(prev => [...prev, pendingOtherProducts.find(product => product.data._id === id)])
				setPendingOtherProducts(prev => prev.filter(product => product.data._id !== id))
			}
		}
	}

	const handleUpdateOrder = async () => {
		try {
			await axios.put(`${BACK_APP_URI}/pedidos/edit/${orderData._id}`, {
				products: orderProducts.filter(product => !product.isTag),
				otherProducts: orderOtherProducts.filter(product => !product.isTag),
				tags: [...orderProducts, ...orderOtherProducts].filter(product => product.isTag),
			})
			setVariable(true)
			setErrorBool(false)
			setAvisomsg("¡Pedido editado correctamente!")
		} catch (error) {
			setErrorBool(true)
			setVariable(true)
			setAvisomsg(error?.response?.data?.msg)

			setTimeout(() => {
				setVariable(false)
			}, 3200)

			console.log(".:Mensaje de error:.")
			console.log(error)
		}
	}

	// Rechazar el pedido
	const handleRejection = () => {
		if (verifyComment) {
			const orderId = id
			const codigo = orderData.codigoPedido + " -R"
			axios({
				method: "put",
				url: `${BACK_APP_URI}/pedidos/edit/${orderId}`,
				data: {
					email: orderData.userLabType,
					state: "Rechazado",
					codigoPedido: codigo,
					comment: texto,
				},
			})
				.then(data => {
					console.log(data)
					setVariable(true)
					setErrorBool(false)
					// setAvisomsg(data.data.msg);
					setTimeout(() => {
						setVariable(false)
						navigate("/orders")
					}, 1200)
				})
				.catch(error => {
					setErrorBool(true)
					setVariable(true)
					setAvisomsg(error?.response?.data?.msg)
					setTimeout(() => {
						setVariable(false)
					}, 3200)
					console.log(".:Mensaje de error:.")
					console.log(error.response.data.msg) // Mostrar el mensaje de error en la consola
				})
		} else {
			setAvisomsg("Debe agregar una observación")
		}
	}

	function filterPendingProducts(arr, itemsToApprove, pendingItemsToApprove, pendingItemTagsToApprove) {
		//La función filterPendingProducts() se utiliza en handleApproved() y handleDeliveredOrder() para manejar que productos siguen en la orden y cuales serán productos pendientes
		arr.forEach(product => {
			const { data, quantityRequested, quantityToSend, isTag } = product

			function addOrEditPendingProduct(newQuantity, arrToAddOrEdit, isTag) {
				if (arrToAddOrEdit.some(pendingProduct => pendingProduct._id === product.data._id)) {
					arrToAddOrEdit.map(pendingProduct => {
						if (pendingProduct._id === product.data._id) {

							pendingProduct.quantityRequested = roundNumber(newQuantity)
						}
						return pendingProduct
					})
				} else {
					arrToAddOrEdit.push({
						_id: data._id,
						data: data,
						quantityRequested: roundNumber(newQuantity),
						isTag: isTag ? true : undefined
					})
				}
			}

			if (!quantityToSend || quantityToSend === "" || (isTag && !Array.isArray(quantityToSend))) {
				if (isTag) {
					addOrEditPendingProduct(quantityRequested, pendingItemTagsToApprove, true)
				} else {
					addOrEditPendingProduct(quantityRequested, pendingItemsToApprove)
				}
			} else {
				if (isTag) {
					let totalToSend = 0
					product.quantityToSend.forEach(item => (totalToSend += convertQuantityToSend(data.measure.name.toUpperCase(), item.measure.toUpperCase(), item.quantityToSend, item.weightOrVolume)))

					if (quantityRequested - totalToSend > 0) {
						addOrEditPendingProduct(quantityRequested - totalToSend, pendingItemTagsToApprove, true)
					} else {
						pendingItemTagsToApprove = pendingItemTagsToApprove.filter(pendingItem => pendingItem.data._id !== data._id)
					}

					quantityToSend.forEach(item => {
						if (!item.quantityToSend || item.quantityToSend === "") return
						itemsToApprove.push({
							_id: item._id,
							data: item,
							quantityToSend: item.quantityToSend,
							currentQuantity: item.currentQuantity,
							quantityRequested: quantityRequested,
						})
					})
				} else {
					const quantityToSendConverted = convertQuantityToSend(data.unitType.name.toUpperCase(), data.unitType.name.toUpperCase(), quantityToSend, data.weightOrVolume)

					if (quantityRequested - quantityToSendConverted > 0) {
						addOrEditPendingProduct(quantityRequested - quantityToSendConverted, pendingItemsToApprove)
					} else {
						pendingItemsToApprove = pendingItemsToApprove.filter(pendingItem => pendingItem.data._id !== data._id)
					}

					itemsToApprove.push({
						...product,
						currentQuantity: data.quantity,
					})
				}
			}
		})

		return { itemsToApprove, pendingItemsToApprove, pendingItemTagsToApprove }
	}

	function addPendingProduct(pendingArr, arrToApprove, tagsArrToApprove) {
		pendingArr.forEach(product => {
			if (product?.isTag) {
				tagsArrToApprove.push({
					_id: product.data._id,
					data: product.data,
					quantityRequested: product.quantityRequested,
					isTag: true
				})
			} else {
				arrToApprove.push({
					_id: product.data._id,
					data: product.data,
					quantityRequested: product.quantityRequested,
				})
			}
		})
	}

	// Aprobar el pedido
	const handleApproved = async () => {
		try {
			const orderId = id
			const acceptedDateOrder = new Date()
			let orderCode
			let orderState

			let productsToApprove = []
			let pendingProductsToApprove = []
			let pendingTagsToApprove = []
			const isBiochemicalOrder = orderProducts.length ? true : false
			const orderProductsArr = isBiochemicalOrder ? orderProducts : orderOtherProducts
			const pendingProductsArr = isBiochemicalOrder ? pendingProducts : pendingOtherProducts

			addPendingProduct(pendingProductsArr, pendingProductsToApprove, pendingTagsToApprove)

			const { itemsToApprove, pendingItemsToApprove, pendingItemTagsToApprove } = filterPendingProducts(orderProductsArr, productsToApprove, pendingProductsToApprove, pendingTagsToApprove)

			productsToApprove = itemsToApprove
			pendingProductsToApprove = pendingItemsToApprove
			pendingTagsToApprove = pendingItemTagsToApprove

			if (
				(productsToApprove.length && productsToApprove.some(product => product.currentQuantity - product.quantityToSend < 0))
			) {
				orderState = "Aceptado sin Stock"
				orderCode = orderData.nroPedido + " -AS"
			} else if (pendingProductsToApprove.length > 0 || pendingTagsToApprove.length > 0) {
				orderCode = orderData.nroPedido + " -AP"
				orderState = "Aceptado"
			} else {
				orderCode = orderData.nroPedido + " -A"
				orderState = "Aceptado"
			}

			// Editar la orden
			const orderResponse = await axios.put(`${BACK_APP_URI}/pedidos/edit/${orderId}`, {
				state: orderState,
				products: isBiochemicalOrder ? productsToApprove : [],
				otherProducts: !isBiochemicalOrder ? productsToApprove : [],
				tags: [],
				acceptedAt: acceptedDateOrder,
				pendingProducts: isBiochemicalOrder ? pendingProductsToApprove : [],
				pendingOtherProducts: !isBiochemicalOrder ? pendingProductsToApprove : [],
				pendingTags: pendingTagsToApprove,
				codigoPedido: orderCode,
				comment: texto,
			})

			setVariable(true)
			setErrorBool(false)
			setAvisomsg("¡Pedido aceptado!")
		} catch (error) {
			setErrorBool(true)
			setVariable(true)
			setAvisomsg(error?.response?.data?.msg)

			setTimeout(() => {
				setVariable(false)
			}, 3200)

			console.log(".:Mensaje de error:.")
			console.log(error)
		}
	}

	const handleDeliveredOrder = async () => {
		try {
			const products = orderProducts
			const otherProducts = orderOtherProducts

			// Descuento de Cantidades en Stock
			if (products.length) {
				for (const product of products) {
					// Actualizar el stock total de los productos que seran enviados
					const productLots = product.data.lots.sort((a, b) => new Date(a.expireDate) - new Date(b.expireDate))
					let indice = 0
					let quantityToSend = product.quantityToSend
					do {
						if (quantityToSend >= productLots[indice].quantity) {
							quantityToSend -= productLots[indice].quantity
							await axios.put(`${BACK_APP_URI}/lotes/edit/${productLots[indice]._id}`, {
								quantity: 0,
								product: product.data._id,
							})
							if (quantityToSend === 0) {
								break
							} else {
								indice++
							}
						} else {
							const updatedQuantity = productLots[indice].quantity - quantityToSend
							await axios.put(`${BACK_APP_URI}/lotes/edit/${productLots[indice]._id}`, {
								quantity: updatedQuantity,
								product: product.data._id,
							})
							quantityToSend = 0
						}
					} while (quantityToSend > 0 && indice < productLots.length)
				}
			}

			if (otherProducts.length) {
				// Lógica para descontar el stock de los productos varios
				for (const otherProduct of otherProducts) {
					const otherProductData = otherProduct.data

					otherProductData.quantity -= otherProduct.quantityToSend

					await axios.put(`${BACK_APP_URI}/otros_productos/edit/${otherProduct.data._id}`, otherProductData)
				}
			}

			//Creación de nueva Orden si hay Productos Pendientes

			// Esta parte del codigo se encarga en volver a juntar los productos dentro de sus etiquetas para poder volver a hacer el filtrado de productos pendientes con filterPendingProducts()
			let pendingProductsToApprove = []
			let pendingTagsToApprove = []
			const isBiochemicalOrder = orderProducts.length ? true : false
			const orderProductsArr = isBiochemicalOrder ? orderProducts : orderOtherProducts
			const pendingProductsArr = isBiochemicalOrder ? pendingProducts : pendingOtherProducts

			function pairProductsWithTheirTags(arr) {
				const newArr = []
				arr.forEach(product => {

					const tagName = product.data.tag.name
					if (tagName === "-") {
						newArr.push(product)
					} else {
						const tagFound = newArr.find(prod => prod.data.name === tagName)

						const productObj = {
							_id: product._id,
							quantityToSend: product.quantityToSend,
							measure: product.data.unitType.name,
							weightOrVolume: product.data.weightOrVolume
						}

						if (tagFound) {
							newArr.map(prod => {
								if (prod.data._id === tagFound.data._id) prod.quantityToSend.push(productObj)

								return prod
							})
						} else {
							newArr.push({
								data: product.data.tag,
								isTag: true,
								quantityRequested: product.quantityRequested,
								quantityToSend: [productObj]
							})
						}
					}
				})
				return newArr
			}

			addPendingProduct(pendingProductsArr, pendingProductsToApprove, pendingTagsToApprove)

			const { itemsToApprove, pendingItemsToApprove, pendingItemTagsToApprove } = filterPendingProducts(pairProductsWithTheirTags(orderProductsArr), [], pendingProductsToApprove, pendingTagsToApprove)

			// Añade propiedad "id" para la creación de nuevo pedido(pedida por el backend)
			pendingProductsToApprove = pendingItemsToApprove.map(pendingProduct => ({ ...pendingProduct, id: pendingProduct._id }))
			pendingTagsToApprove = pendingItemTagsToApprove.map(pendingTag => ({ ...pendingTag, id: pendingTag._id }))

			const codigoSplit = orderData.codigoPedido.split(" -")

			if (pendingProductsToApprove.length || pendingTagsToApprove.length) {

				axios({
					method: "post",
					url: `${BACK_APP_URI}/pedidos/add`,
					data: {
						labOrigin: orderData.labOrigin._id,
						username: orderData.userLabType.username,
						products: isBiochemicalOrder ? pendingProductsToApprove : [],
						otherProducts: !isBiochemicalOrder ? pendingProductsToApprove : [],
						productTags: isBiochemicalOrder ? pendingTagsToApprove : [],
						otherProductTags: !isBiochemicalOrder ? pendingTagsToApprove : [],
						isFromAdmin: true,
						comment: "Esta orden ha sido generada a partir de los productos pendientes de la orden número: " + codigoSplit[0],
					},
				})
			}

			//Actualizacion de Estado y Codigo de Orden
			const orderId = id
			const orderStatus = "Enviado"

			let codigo
			if (codigoSplit[1] === "A") {
				codigo = codigoSplit[0] + " -E"
			} else if (codigoSplit[1] === "AS") {
				codigo = codigoSplit[0] + " -ES"
			} else if (codigoSplit[1] === "AP") {
				codigo = codigoSplit[0] + " -E"
			}

			const orderResponse = await axios.put(`${BACK_APP_URI}/pedidos/edit/${orderId}`, {
				state: orderStatus,
				deliveredAt: new Date(),
				codigoPedido: codigo,
				products: orderProducts,
				otherProducts: orderOtherProducts,
				pendingProducts: [],
				pendingOtherProducts: [],
				pendingTags: [],
			})
			console.log(`Su pedido ha sido ${orderStatus}!`, orderResponse.data)
			setVariable(true)
			setErrorBool(false)
			setAvisomsg("¡Pedido enviado!")

		} catch (error) {
			setErrorBool(true)
			setVariable(true)
			setAvisomsg(error?.response?.data?.msg)
			setTimeout(() => {
				setVariable(false)
			}, 3200)
			console.log(".:Mensaje de error:.")
			console.log(error)
		}
	}

	const handleReceivedOrder = async () => {
		const receivedDate = new Date()

		try {
			const orderId = id
			const orderStatus = "Recibido"
			const codigoSplit = orderData.codigoPedido.split(" -")
			let codigo
			if (codigoSplit[1] === "E") {
				codigo = codigoSplit[0] + " -RC"
			} else if (codigoSplit[1] === "ES") {
				codigo = codigoSplit[0] + " -RI"
			} else if (codigoSplit[1] === "EP") {
				codigo = codigoSplit[0] + " -RP"
			}

			const orderResponse = await axios.put(`${BACK_APP_URI}/pedidos/edit/${orderId}`, {
				state: orderStatus,
				receivedAt: receivedDate,
			})

			console.log(`Su pedido ha sido ${orderStatus}!`, orderResponse.data)
			setVariable(true)
			setErrorBool(false)
			setAvisomsg("¡Pedido recibido!")
			/* setTimeout(() => {
		  setVariable(false);
		  navigate('/orders');
		}, 1200); */
		} catch (error) {
			setErrorBool(true)
			setVariable(true)
			setAvisomsg(error?.response?.data?.msg)

			setTimeout(() => {
				setVariable(false)
			}, 3200)

			console.log(".:Mensaje de error:.")
			console.log(error.response.data.msg)
		}
	}

	const handleRefreshPage = () => {
		setVariable(false)
		navigate("/orders")
		console.log("hello!")
	}

	const handleInputChange = event => {
		const inputValue = event.target.value

		// Limitar la longitud del texto
		if (inputValue.length <= maxCaracteres) {
			setTexto(inputValue)
		}

		if (inputValue.length !== 0) {
			setVerifyComment(true)
		}
	}
	// console.log(orderProducts)
	return (
		<>
			<div className="main-container">
				<div className="header--container">
					<div className="order--header-cont">
						<div className="order--header-row">
							<h1 className="order--title">Carga pedido</h1>

							{/* Tab */}
							<div className="provider-cont">
								{offDate && tabSection === "various" && role === "sede" && <p className="text-danger">Fuera del rango de pedido</p>}
								<Form
									className="all-tag-form"
									onSubmit={e => e.preventDefault()}
								>

								</Form>
							</div>
						</div>
					</div>
					<div className="header-edit-order">
						<div className="order-title-edit">
							<b>Sede:</b> {`${orderData?.labOrigin?.name || ""}`}
						</div>

						<div className="order-title-edit">
							<b>Número de Orden: </b> {`${orderData?.codigoPedido || ""}`}
						</div>

						{orderData.isFromBiochemicalRole && (
							<div className="order-title-edit">
								<b><i>Autopedido</i></b>
							</div>
						)}

					</div>

				</div>

				<div className="card--container">
					{/* Tab */}
					<div className="order-search">
						{offDate && tabSection === "various" && role === "sede" && <p className="text-danger">Fuera del rango de pedido</p>}
						<Form
							className="all-tag-form"
							onSubmit={e => e.preventDefault()}
						>
							<Form.Group controlId="exampleForm.ControlInput1">
								<Form.Control
									className="all-tag-search"
									type="text"
									value={search}
									onChange={e => {
										setSearch(e.target.value)
										setProductPageNumber(0)
									}}
									placeholder="Buscar..."
								/>
							</Form.Group>
						</Form>
						{role !== "sede" && !orderData?.state && (
							<div className="button-carga">
								<Button
									onClick={() => setShowAddProductModal(true)}
									className="button-carga-order"
								>
									Añadir Productos
								</Button>
							</div>
						)}
					</div>
					<div className="table-container-new">
						<div className="table-striped table-bordered table-hover">
							{/* Bioquímicos */}
							{tabSection === "biochemicals" && (
								orderProducts?.length > 0 ? (
									<ProductsTable
										orderItems={orderProducts}
										role={role}
										originalTags={originalTags} 

										setOrderItems={setOrderProducts}
										itemsStartIndex={productsStartIndex}
										itemsEndIndex={productsEndIndex}
										disabled={orderData.state}
										handleItems={handlePendingProductsCheckbox}
										orderState={orderData?.state}
										isProductTable
									/>
								) : (
									<div style={{ textAlign: 'center' }}>Productos bioquímicos no encontrados.</div>
								)
							)}

							{/* Varios */}
							{tabSection === "various" && (
								orderOtherProducts?.length > 0 ? (
									<ProductsTable
										orderItems={orderOtherProducts}
										role={role}
										originalTags={originalOtherTags}
										setOrderItems={setOrderOtherProducts}
										itemsStartIndex={productsStartIndex}
										itemsEndIndex={productsEndIndex}
										disabled={orderData.state}
										handleItems={handlePendingOtherProductsCheckbox}
										orderState={orderData?.state}
									/>
								) : (
									<div>Productos varios no encontrados</div>
								)
							)}
						</div>

						{(orderProducts.length > productsPerPage || orderOtherProducts.length > productsPerPage) && (

							<PaginationComponent productPageNumber={productPageNumber} setProductPageNumber={setProductPageNumber} productsPageCount={tabSection === "biochemicals" ? productsPageCount : otherProductsPageCount} />
						)}



						{tabSection === "biochemicals" && role !== "sede" && pendingProducts.length > 0 && (
							<PendingProductsTable
								pendingItems={pendingProducts}
								disabled={orderData.state}
								handleItems={handlePendingProductsCheckbox}
								itemsStartIndex={pendingProductsStartIndex}
								itemsEndIndex={pendingProductsEndIndex}
							/>
						)}
						{tabSection === "various" && role !== "sede" && pendingOtherProducts.length > 0 && (
							<PendingProductsTable
								pendingItems={pendingOtherProducts}
								disabled={orderData.state}
								handleItems={handlePendingOtherProductsCheckbox}
								itemsStartIndex={pendingProductsStartIndex}
								itemsEndIndex={pendingProductsEndIndex}
							/>
						)}
					</div>

					{(pendingProducts.length > pendingProductsPerPage || pendingOtherProducts.length > pendingProductsPerPage) && (
						<PaginationComponent productPageNumber={pendingProductPageNumber} setProductPageNumber={setPendingProductPageNumber} productsPageCount={tabSection === "biochemicals" ? pendingProductsPageCount : pendingOtherProductsPageCount} />
					)}

					<div className="order--header">
						{/* Observaciones */}
						{role === "sede" ? (
							orderData?.state ? (
								orderData.comment ? (
									<div className="cont-observaciones">
										<h3>
											Observaciones: <small>{role}</small>
										</h3>
										<p>{orderData.comment}</p>
									</div>
								) : null
							) : null
						) : orderData?.state ? (
							orderData.comment ? (
								<div className="cont-observaciones">
									<h3>
										Observaciones: <small>{role}</small>
									</h3>
									<p>{orderData.comment}</p>
								</div>
							) : null
						) : (
							<div className="cont-observaciones">
								<h4>Observaciones: </h4>
								<TextField
									name="comments"
									id="comment"
									multiline
									rows={4}
									fullWidth
									value={texto}
									onChange={e => handleInputChange(e)}
									placeholder="Para rechazar un pedido, la observación es obligatoria..."
									inputProps={{ maxLength: maxCaracteres }}
									helperText={`Caracteres restantes: ${maxCaracteres - texto.length}/${maxCaracteres}`}
								/>
							</div>
						)}
						<div className="edit-order-btn">
							{/* ORDER BUTTONS */}
							<div className="content-card-order">
								{role === "sede" ? (
									<>
										<Button
											className="btn--order btn-acept-order"
											children="Actualizar pedido"
											onClick={handleUpdateOrder}
											disabled={ALL_ORDER_STATES.includes(orderData?.state)}
										/>
									</>
								) : (
									/* ADMIN */
									<div className="admin-button-cont">
										<div className="flex-gap-2">
											<Button
												className="btn--order btn-acept-order"
												children="Aceptar pedido"
												onClick={handleApproved}
												disabled={ALL_ORDER_STATES.includes(orderData?.state) || (!orderProducts?.length && !orderOtherProducts?.length)}
											/>
											<Button
												className="btn--order btn-danger-order"
												children="Rechazar pedido"
												onClick={handleRejection}
												disabled={ALL_ORDER_STATES.includes(orderData?.state)}
											/>
										</div>
									</div>
								)}
								<div className="button--column">
									{/* Envío el pedido */}
									{role !== "sede" ? (
										<Button
											className="btn--order btn-send-order"
											onClick={handleDeliveredOrder}
											disabled={
												["Enviado", "Recibido", "Rechazado", undefined].includes(orderData?.state) ||
												(orderProducts.length && orderProducts.some(product => product.data.quantity - product.quantityToSend < 0)) ||
												(orderOtherProducts.length && orderOtherProducts.some(product => product.data.quantity - product.quantityToSend < 0))
											}
										>
											<span style={{ paddingRight: "5px" }}>Enviar Pedido</span>
											<LocalShippingOutlinedIcon style={{ width: '22px' }}
											/>
										</Button>
									) : (
										<>
											{orderData?.state !== undefined && (
												<Button
													className="btn--order btn-send-order"
													onClick={handleReceivedOrder}
													disabled={["Aceptado", "Aceptado sin Stock", "Rechazado", "Recibido"].includes(orderData?.state)}
												>

													<CheckCircleOutlineIcon style={{ width: '22px' }}
													/>
													<span >Orden Recibida</span>

												</Button>
											)}
										</>
									)}

									{role === "sede" ? (
										<div className="flex-center mt-18">
											<Button
												className="btn--order btn-pdf-order"
												disabled={false}
											>
												<PDFDownloadLink style={{ textDecoration: 'none' }}
													document={
														<OrderPDF
															orderInfo={{
																nroPedido: orderData?.nroPedido,
																lab: orderData?.labOrigin?.name,
																isAccepted: ["Aceptado", "Aceptado sin Stock"].includes(orderData?.state),
																role: role,
															}}
															orderProduct={orderProducts}
															orderOtherProducts={orderOtherProducts}
															allProducts={allProducts}
															allOtherProducts={allOtherProducts}
															orderState={orderData?.state ? orderData?.state.toUpperCase() : "PENDIENTE"}
															tabSection={tabSection}
														/>
													}
													fileName={`Pedido ${orderData?.state ? orderData?.state : "Pendiente"} N° ${orderData?.nroPedido}`}
												>
													<div className="btn-pdf-order-text">
														Descargar PDF
														<PictureAsPdfOutlinedIcon style={{ width: '22px' }} />
													</div>
												</PDFDownloadLink>
											</Button>
										</div>
									) : (
										<div className="flex-center mt-18">
											<Button
												className="btn--order btn-pdf-order"
												disabled={false}
											>
												<PDFDownloadLink style={{ textDecoration: 'none' }}
													document={
														<OrderPDF
															orderInfo={{
																nroPedido: orderData?.nroPedido,
																lab: orderData?.labOrigin?.name,
																isAccepted: ["Aceptado", "Aceptado sin Stock"].includes(orderData?.state),
																title: "PEDIDO ENVIADO", // Título para otros roles

															}}
															orderProduct={orderProducts}
															orderOtherProducts={orderOtherProducts}
															allProducts={allProducts}
															allOtherProducts={allOtherProducts}
															orderState={orderData?.state ? orderData?.state.toUpperCase() : "PENDIENTE"}
															tabSection={tabSection}
														/>
													}
													fileName={`Pedido ${orderData?.state ? orderData?.state : "Pendiente"} N° ${orderData?.nroPedido}`}
												>
													<div className="btn-pdf-order-text">
														Descargar PDF
														<PictureAsPdfOutlinedIcon style={{ width: '22px' }} />
													</div>
												</PDFDownloadLink>
											</Button>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>

				</div>
				<AddProductToOrderModal
					show={showAddProductModal}
					setShow={setShowAddProductModal}
					tabSection={tabSection}
					lab={orderData?.labOrigin}
					productsFromOrder={orderProducts}
					otherProductsFromOrder={orderOtherProducts}
					orderId={id}
				/>

				{/* Animación de Éxito */}
				{variable ? (
					<AnimacionSuccessful
						errorBool={errorBool}
						avisomsg={avisomsg}
						refreshPage={handleRefreshPage}
					/>
				) : (
					<></>
				)}
			</div>
		</>
	)
}
