
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { PDFDownloadLink, Document, Page, Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import innovis_adobe_express from '../../Assets/Img/innovis_adobe_express.png';
import { utils, writeFile } from "xlsx"


export default function ResumenPDFModal({ show, onHide, filters, filteredOrders, tagProducts = [] }) {
    const [groupedData, setGroupedData] = useState([]);

    const calculatePeriod = () => {
        const start = moment(filters.startDate, "DD/MM/YYYY");
        const end = moment(filters.endDate, "DD/MM/YYYY");
        if (!start.isValid() || !end.isValid()) return "Período inválido";

        const diffInMonths = end.diff(start, "months") + 1;
        return `Últimos ${diffInMonths} mes${diffInMonths === 1 ? "" : "es"}`;
    };

    useEffect(() => {
        if (filteredOrders.length === 0) {
            console.log("No hay órdenes filtradas");
            setGroupedData([]);
            return;
        }

        if (tagProducts.length === 0) {
            console.log("No hay etiquetas disponibles, los productos serán clasificados como 'Sin etiqueta'");
        }

        const grouped = filteredOrders.reduce((acc, order) => {
            const processProducts = (products, tagKey) => {
                products.forEach((product) => {
                    if (!product?.data) {
                        console.log("Producto sin datos:", product);
                        return;
                    }

                    // Busca la etiqueta asociada al producto
                    const relatedTag = tagProducts.find((tag) =>
                        tag[tagKey]?.some((tagProduct) => tagProduct._id === product.data._id)
                    );

                    // si tiene la etiqueta global, muestra el nombre del producto individual
                    const isDefaultTag = relatedTag?.name === "-";

                    const tagName = isDefaultTag || !relatedTag ? product.data.name : relatedTag.name;

                    const key = `${tagName}_${product.data._id}`; // evitar duplicados

                    if (!acc[key]) {
                        acc[key] = {
                            name: tagName,
                            productName: product.data.name,
                            totalQuantity: 0,
                            totalOrders: 0,
                        };
                    }

                    acc[key].totalQuantity += product.quantityRequested || 0;
                    acc[key].totalOrders += 1;
                });
            };

            processProducts(order.products || [], "products");
            processProducts(order.otherProducts || [], "otherProducts");

            return acc;
        }, {});

        setGroupedData(Object.values(grouped));
    }, [filteredOrders, tagProducts]);






    // Estilos para el PDF
    const styles = StyleSheet.create({

        body: {
            paddingTop: 35,
            paddingBottom: 65,
            alignItems: "center",
        },
        header: {
            width: '90%',
            display: 'flex',
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 16,
            padding: '30px',
            alignItems: 'flex-start',
            backgroundColor: '#f4fbfa',
            borderRadius: '10px',
        },
        highlight: {
            color: "#fff",
            fontSize: 9,
            backgroundColor: "rgb(47, 161, 161)",
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: '3px',
            marginBottom: '5px',
            textTransform: 'uppercase',
        },
        highlightFooter: {
            width: '90%',
            display: 'flex',
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: 16,
            padding: '20px',
            alignItems: 'flex-start',
            backgroundColor: '#f4fbfa',
            borderRadius: '10px',
        },
        membrete: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '5px',
        },
        membreteLogo: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        logo: {
            width: 80,
            height: 60,
        },
        infoLogo: {
            fontSize: 10,
            fontWeight: 'normal',
            padding: 5,

        },

        title: {
            fontSize: 28,
            fontWeight: 'extrabold',
            color: '#056a6c'
        },
        date: {
            textAlign: "center",
            fontSize: 10,
            fontWeight: 'bold',
        },

        table: {
            display: "table",
            width: "90%",
            borderCollapse: "collapse"
        },
        tableRow: {
            flexDirection: "row",
            borderBottom: "1px solid #ddd"
        },
        tableCell: {
            flex: 1,
            padding: 5,
            fontSize: 10,
            textAlign: "left"
        },
        footer: {
            position: "absolute",
            bottom: 20,
            left: 0,
            right: 0,
            textAlign: "center",
            fontSize: 9,
            padding: 10,
            borderTopWidth: 1,
            borderColor: '#cce8e8',
            color: '#056a6c',
        },

    });


    const exportToExcel = () => {
        if (groupedData.length === 0) return;

        const ordersData = groupedData.map(item => ({
            Nombre: item.name,
            Cantidad: item.totalQuantity,
            "Pedidos Realizados": item.totalOrders,
        }));

        const worksheet = utils.json_to_sheet(ordersData);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Resumen");
        writeFile(workbook, `Resumen_Pedidos_${filters.startDate}_${filters.endDate}.xlsx`);
    };




    const ResumenPDF = () => {
        const totalOrder = groupedData.reduce((sum, item) => sum + item.totalOrders, 0);
        const productMostOrder = groupedData.reduce(
            (max, item) => (item.totalQuantity > max.totalQuantity ? item : max),
            { name: "", totalQuantity: 0 }
        );

        return (
            <Document>
                <Page size="A4" style={styles.body}>
                    <View style={styles.header}>
                        <View style={styles.membrete}>
                            <Text style={styles.title}>Resumen de Pedidos</Text>
                            <Text style={styles.date}>{`Fecha: ${filters.startDate} - ${filters.endDate}`}</Text>
                            <Text style={styles.date}>{`Período: ${calculatePeriod()}`}</Text>
                            <Text style={styles.highlight}>{`Laboratorio: ${filters.labName || "Todos los laboratorios"}`}</Text>
                        </View>
                        <View style={styles.membreteLogo}>
                            <Image src={innovis_adobe_express} style={styles.logo} />
                            <Text style={styles.infoLogo}>Laboratorio bioquímico integral</Text>
                        </View>
                    </View>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>Nombre</Text>
                            <Text style={styles.tableCell}>Cantidad total</Text>
                            <Text style={styles.tableCell}>Pedidos realizados</Text>
                        </View>
                        {groupedData.length > 0 ? (
                            groupedData.map((data, index) => (
                                <View style={styles.tableRow} key={index}>
                                    <Text style={styles.tableCell}>{data.name}</Text>
                                    <Text style={styles.tableCell}>{data.totalQuantity}</Text>
                                    <Text style={styles.tableCell}>{data.totalOrders}</Text>
                                </View>
                            ))
                        ) : (
                            <View style={styles.tableRow}>
                                <Text style={styles.tableCell}>No se encontraron resultados</Text>
                            </View>
                        )}
                    </View>
                    {/* Resumen al final de la tabla */}
                    <View
                        style={{
                            padding: '18px',
                            backgroundColor: '#f4fbfa',
                            borderRadius: '10px',
                            marginTop: 20,
                            alignItems: "flex-end",
                            textAlign: "left",
                            width: "90%",
                        }}
                    >
                        <Text style={{ fontSize: 10, fontWeight: "normal" }}>
                            <Text style={{ color: "rgb(47, 161, 161)", fontWeight: "semibold" }}>
                                Total de pedidos:
                            </Text>{" "}
                            {totalOrder} en el último mes
                        </Text>
                        <Text style={{ fontSize: 10, fontWeight: "normal", marginTop: 2 }}>
                            <Text style={{ color: "rgb(47, 161, 161)", fontWeight: "semibold" }}>
                                Producto más pedido:
                            </Text>{" "}
                            {productMostOrder.name}
                        </Text>
                    </View>

                    <View style={styles.footer}>
                        <Text>INNOVIS | LABORATORIOS BIOQUÍMICOS</Text>
                    </View>
                </Page>
            </Document>
        );
    };



    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Resumen de pedidos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <strong>Fecha:</strong> {filters.startDate} - {filters.endDate}
                </p>
                <p>
                    <strong>Período:</strong> {calculatePeriod()}
                </p>
                <p>
                    <strong>Laboratorio:</strong> {filters.labName || "Todos los laboratorios"}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-modal-order btn-primary"
                    variant="primary"
                    style={{ marginRight: "10px" }}
                    onClick={exportToExcel}
                >
                    Descargar Excel
                </Button>

                <PDFDownloadLink style={{ textDecoration: 'none' }}
                    document={<ResumenPDF />}
                    fileName={`Resumen_${filters.startDate}_${filters.endDate}.pdf`}
                >
                    {({ loading }) => (
                        <Button className="btn-modal-order btn-primary"
                            style={{ textDecoration: 'none' }}
                            variant="primary" disabled={groupedData.length === 0}>
                            Descargar PDF
                        </Button>
                    )}
                </PDFDownloadLink>
            </Modal.Footer>


        </Modal>
    );
}
