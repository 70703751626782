import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import React, { useState } from "react"
import { Card, Form, InputGroup } from "react-bootstrap"

export default function BoxSelector({ title, allItems, itemsAssigned, setItemsAssigned }) {
	const [inputValue, setInputValue] = useState("")
	const [showAll, setShowAll] = useState(false)

	return (
		<Card
			className="input--primary mb-3"
			style={{ width: "100%" }}
		>
			<Card.Body>
				<Card.Text>{title}</Card.Text>
				<div
					style={{
						display: "flex",
						alignItems: "baseline",
						justifyContent: "center",
						gap: ".3rem",
					}}
				>
					<p>Ver todo</p>
					<Form.Check
						type="switch"
						id="extra-order-switch"
						checked={showAll}
						onChange={() => setShowAll(prev => !prev)}
					></Form.Check>
				</div>
				<Form.Control
					className="input-primary"
					type="text"
					style={{
						marginBottom: ".5rem",
					}}
					value={inputValue}
					onChange={e => setInputValue(e.target.value)}
				/>
				<div
					style={{
						maxHeight: "400px",
						overflowY: "auto",
					}}
				>
					{allItems.map(item => {
						// Filtro del buscador
						if (!showAll && inputValue === "") return
						if (!showAll && !item.name.toUpperCase().includes(inputValue.toUpperCase())) return

						// Filtro de items ya agregados
						if (itemsAssigned && itemsAssigned.some(itemAssigned => itemAssigned.name === item.name)) return

						return (
							<FormGroup key={item.name}>
								<FormControlLabel
									control={<Checkbox onChange={() => setItemsAssigned([...itemsAssigned, item])} />}
									label={item.name}
								/>
							</FormGroup>
						)
					})}
				</div>

				{itemsAssigned.length > 0 && (
					<>
						<hr />

						{itemsAssigned.map(item => {
							return (
								<FormGroup key={item.name}>
									<FormControlLabel
										control={
											<Checkbox
												onChange={() => setItemsAssigned(itemsAssigned.filter(prevItem => prevItem._id !== item._id))}
												checked={true}
											/>
										}
										label={item.name}
									/>
								</FormGroup>
							)
						})}
					</>
				)}
			</Card.Body>
		</Card>
	)
}
