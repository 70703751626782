import React from "react"
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer"
import innovis_adobe_express from "./../../../../Assets/Img/innovis_adobe_express.png"

const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		alignItems: "center",
	},
	header: {
		width: '95%',
		display: 'flex',
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 16,
		padding: '30px',
		alignItems: 'flex-start',
		backgroundColor: '#f4fbfa',
		borderRadius: '10px',
	},
	membrete: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		gap: '5px',
	},
	membreteLogo: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	logo: {
		width: 80,
		height: 60,
	},

	title: {
		fontSize: 24,
		fontWeight: "extrabold",
		color: '#056a6c'
	},
	highlight: {
		color: "#fff",
		fontSize: 9,
		backgroundColor: "rgb(47, 161, 161)",
		padding: 5,
		paddingLeft: 10,
		paddingRight: 10,
		borderRadius: '3px',
		marginBottom: '5px',
	},
	subtitule: {
		fontSize: 16,
		fontWeight: "bold",
	},
	campo: {
		fontSize: 10,
		fontWeight: "bold",
	},
	cardInfo: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	info: {
		fontSize: 10,
		fontWeight: 'normal',
		textAlign: "left",
	},
	infoLogo: {
		fontSize: 10,
		fontWeight: 'normal',
		padding: 5,

	},
	date: {
		textAlign: "center",
		fontSize: 10,
		fontWeight: 'bold',
	},
	spacer: {
		width: 40,
	},
	table: {
		display: "table",
		width: "95%",
		borderRightWidth: 1,
		borderBottomWidth: 1,
		borderLeftWidth: 1,
		borderTopWidth: 1,
		borderRadius: '10px',
		borderColor: '#d5dbdb',
		textAlign: 'start',
	},
	tableRow: {
		flexDirection: "row",
		textAlign: 'left',

	},
	tableRowTitle: {
		borderRadius: '5px',
		backgroundColor: '#029598',
		padding: 6,
		flexDirection: "row",
		borderBottom: '0px',
		textAlign: 'left',


	},
	tableCol: {

		width: "30%",
		borderStyle: 'solid',
		borderColor: '#d5dbdb',
	},

	tableColFirst: {
		width: "30%",
		borderRightWidth: 0.2,
		borderColor: '#d5dbdb',
	},
	tableCell: {
		textAlign: 'left',
		margin: "auto",
		marginTop: 4,
		marginBottom: 4,
		fontSize: 7.5,
		padding: 5,
	},
	tableTitle: {


		margin: "auto",
		marginTop: 2,
		marginBottom: 2,
		fontSize: 10,
		color: "white",
		padding: 5,
		textAlign: 'left',
	},
	footer: {
		position: "absolute",
		bottom: 20,
		left: 0,
		right: 0,
		textAlign: "center",
		fontSize: 9,
		padding: 10,
		borderTopWidth: 1,
		borderColor: '#cce8e8',
		color: '#056a6c',

	},
})

const StockoutPDF = ({ productsToOrder, isOtherProducts }) => {
	const titulo = "CONTROL DE STOCK"

	const PRODUCTS_TITLES = [
		"Cod.",
		"Nomb.",
		"Prov.",
		"Marca",
		"Und.",
		"Nbu",
		"Lote",
		"C/ Lote",
		"Ing.",
		"Vto.",
		"Cant.",
		"Estado",
		"Falt.",
	]

	const OTHER_PRODUCTS_TITLES = [
		"Código",
		"Nombre",
		"Proveedor",
		"Categoría",
		"Cantidad",
		"Estado",
		"Faltantes",
	]

	return (
		<Document>
			<Page style={styles.body}>
				<View style={styles.header}>
					<View style={styles.membrete}>
						<Text style={styles.title}>{titulo}</Text>
						<Text style={styles.subtitule}>INNOVIS LABORATORIOS BIOQUÍMICOS</Text>
						<View style={styles.spacer} />

						<View style={styles.cardInfo}>
							<Text style={styles.date}>Fecha: {new Date().toLocaleDateString()}</Text>
						</View>
					</View>
					<View style={styles.membreteLogo}>
						<Image
							src={innovis_adobe_express}
							style={styles.logo}
						/>
						<Text style={styles.infoLogo}>Laboratorio bioquímico integral</Text>

					</View>
				</View>
				<View style={[styles.table, { display: 'flex', justifyContent: 'flex-start', textAlign: 'start' }]}>
					<View style={styles.tableRowTitle}>
						{(isOtherProducts ? OTHER_PRODUCTS_TITLES : PRODUCTS_TITLES).map(title => {
							return (
								<View style={styles.tableCol} key={title}>
									<Text style={styles.tableTitle}>{title}</Text>
								</View>
							)
						})}
					</View>
					{!isOtherProducts && productsToOrder.map(({ product, lot }) => {
						// Para mostrar los productos faltantes 
						const stockMinimum = product.usoSmnal || 0; 
						const totalQuantity = product.quantity;

						const restProduct = stockMinimum - totalQuantity > 0 ? stockMinimum - totalQuantity : 0;


						return (
							<View style={[styles.tableRow, { borderTopWidth: 1, borderTopColor: "#d5dbdb", borderTopStyle: "solid" }]}
								key={lot?._id}
							>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{product?.code}</Text>
								</View>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{product?.name}</Text>
								</View>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{product?.provider.name}</Text>
								</View>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{product?.brand}</Text>
								</View>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{product?.unitType?.name || product?.measure?.name}</Text>
								</View>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{product?.ubn}</Text>
								</View>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{lot?.lotNumber}</Text>
								</View>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{lot?.lotQuantity}</Text>
								</View>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{lot?.acquireDate}</Text>
								</View>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{lot?.expireDate}</Text>
								</View>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{lot?.quantity}</Text>
								</View>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{lot?.state}</Text>
								</View>
								{/* Columna de productos faltantes */}
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>
										{restProduct > 0 ? `Faltan ${restProduct} productos` : "Stock suficiente"}


									</Text>

								</View>
							</View>
						)
					})}
					{isOtherProducts && productsToOrder.map(({ product }) => {
						return (
							<View
								style={styles.tableRow}
								key={product?._id}
							>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{product?.code || "-"}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.name}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.provider.name}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.category.name || "-"}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.quantity}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.state}</Text>
								</View>
								{/* Columna de productos faltantes */}
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>
										{product.usoSmnal - product.quantity > 0
											? `Faltan ${product.usoSmnal - product.quantity} productos`
											: "Stock suficiente"}
									</Text>
								</View>

							</View>
						)
					})}
				</View>
				{/* Footer */}
				<View style={styles.footer}>
					<Text>INNOVIS | LABORATORIOS BIOQUÍMICOS</Text>
				</View>
			</Page>
		</Document>
	)
}

export default StockoutPDF
