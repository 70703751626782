import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from 'moment';
import axios from "axios";
import Cookies from 'js-cookie';
import '../Main.css';

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const SedeMain = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    const updateUser = async (id, startDateOrder, endDateOrder, updatedLabOrders) => {
        try {
            console.log('Intentando actualizar usuario con fechas:', {
                startDateOrder: startDateOrder.format('YYYY-MM-DD'),
                endDateOrder: endDateOrder.format('YYYY-MM-DD')
            });

            const response = await axios({
                method: "put",
                url: `${BACK_APP_URI}/users/edit/${id}`,
                data: {
                    role: "sede",
                    startDateOrder: startDateOrder.toISOString(),
                    endDateOrder: endDateOrder.toISOString(),
                    labOrders: updatedLabOrders
                }
            });
            
            return true;
        } catch (error) {
            console.error("Error actualizando usuario:", error);
            return false;
        }
    };

    // Obtener datos de usuarios
    useEffect(() => {
        const getDataUser = async () => {
            try {
                const token = Cookies.get("token");
                const response = await axios.get(`${BACK_APP_URI}/users`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                });
                setUsers(response.data.users);
            } catch (error) {
                console.error("Error obteniendo usuarios:", error);
            }
        };
        
        getDataUser();
    }, []);

    // Manejar actualización de fechas
    useEffect(() => {

        (async () => {
            if (users.length === 0) {
                console.log('No hay usuarios cargados todavía');
                return;
            }

            const sede = Cookies.get('username');
            
            const sedeUser = users.find((user) => user.username === sede);

            if (!sedeUser) {
                console.log('No se encontró el usuario de la sede');
                return null;
            }

            const currentDate = moment();
            const existingEndDate = moment(sedeUser.endDateOrder);
            
            // Si la fecha actual es posterior a la fecha de fin
            if (currentDate.isAfter(existingEndDate)) {
                // Calcular nueva fecha de inicio 
                let newStartDate = moment().utc(startDate).utcOffset(0)
                    .endOf('month')
                    .subtract(7, 'days')
                    .hour(8)
                    .minute(0)

                // Calcular nueva fecha de fin
                let newEndDate = moment().utc(startDate).utcOffset(0)
                    .add(1, 'month')
                    .startOf('month')
                    .add(7, 'days')
                    .hour(8)
                    .minute(0)

                // Ajustar si cae en fin de semana
                const startDayOfWeek = newStartDate.day();
                if (startDayOfWeek === 6) { // Saturday
                    newStartDate = newStartDate.subtract(1, 'days');
                } else if (startDayOfWeek === 0) { // Sunday
                    newStartDate = newStartDate.subtract(2, 'days');
                }
        
                const endDayOfWeek = newEndDate.day();
                if (endDayOfWeek === 6) { // Saturday
                    newEndDate = newEndDate.subtract(1, 'days');
                } else if (endDayOfWeek === 0) { // Sunday
                    newEndDate = newEndDate.subtract(2, 'days');
                }

                const updatedLabOrders = sedeUser.labOrders.map(labOrder => {
                    return { ...labOrder, quantityAvailable: 2 }
                })

                const success = await updateUser(
                    sedeUser._id,
                    newStartDate,
                    newEndDate,
                    updatedLabOrders
                );

                if (success) {
                    setStartDate(newStartDate);
                    setEndDate(newEndDate);
                    console.log('Fechas actualizadas exitosamente');
                }
            }

        })()

    }, [users]);

    return (
        <div className="main-container">
            <div className="header-sede-main">
                <h1 className="title-sede-main">Nuevo pedido</h1>

                <Link to="/orders/add" className="mx-auto">
                    <Button className="button-sede-main">
                        Generar pedido
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default SedeMain;