import React, { useState, useEffect } from "react"
import Cookies from "js-cookie"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import Form from "react-bootstrap/Form"
import { useForm } from "react-hook-form"
import "../Users.css"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import moment from "moment"
import "moment/locale/es"
import { DateRangePicker } from "react-dates"
import BoxSelector from "../Components/BoxSelector"
import EditPasswordModal from "./EditPasswordModal"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import AddExtraOrderModal from "./AddExtraOrderModal"
import { Button } from "@mui/material"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function UserForm(props) {
	const [isAdmin, setIsAdmin] = useState(false)
	const navigate = useNavigate()
	const [fname, setFname] = useState("")
	const [lname, setLname] = useState("")
	const [position, setPosition] = useState("")
	const [role, setRole] = useState("")
	const [roleName, setRoleName] = useState("")
	const [username, setUsername] = useState("")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [isPasswordShown, setIsPasswordShown] = useState(false)
	const [labOrders, setLabOrders] = useState([])
	const [biochemicalsNotAllowed, setBiochemicalsNotAllowed] = useState(false)

	const [isExtraLabOrdersOpen, setIsExtraLabOrdersOpen] = useState(false)

	// Buscador de categorías de varios
	const [categoriesFound, setCategoriesFound] = useState([])
	const [categoriesAssigned, setCategoriesAssigned] = useState([])

	// Buscador de categorías de varios
	const [labsFound, setLabsFound] = useState([])
	const [labsAssigned, setLabsAssigned] = useState([])

	// Productos habilitados para pedir rol bioquimico
	const [productsAndTagsFromLabsAssigned, setProductsAndTagsFromLabsAssigned] = useState([])
	const [productsAndTagsAllowed, setProductsAndTagsAllowed] = useState([])

	// Date Range Picker
	moment.locale("es") // Establece el idioma para Moment.js
	const year = moment().format("YYYY")
	const month = moment().format("M")
	const [startDate, setStartDate] = useState()
	const [endDate, setEndDate] = useState()

	const [focusedInput, setFocusedInput] = useState(null)

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm()

	useEffect(() => {
		setFname(props.fname || "")
		setValue("fname", props.fname || "")
		setLname(props.lname || "")
		setValue("lname", props.lname || "")
		setPosition(props.position || "")
		setValue("position", props.position || "")
		setRole(props.role || "")
		setValue("role", props.role || "")
		setRoleName(props.roleName || "")
		setValue("roleName", props.roleName || "")
		setUsername(props.username || "")
		setValue("username", props.username || "")
		setEmail(props.email || "")
		setValue("email", props.email || "")
		setLabOrders(props.labOrders || [])
		setBiochemicalsNotAllowed(props.biochemicalsNotAllowed || false)
		setStartDate(props.startDate || moment(year + "-" + month + "-" + 20, "YYYY-MM-DD"))
		setEndDate(props.endDate || moment(year + "-" + month + "-" + 4, "YYYY-MM-DD").add(1, "M"))
		setCategoriesAssigned(props.categoriesAssigned || [])
		setLabsAssigned(props.labsAssigned || [])
		setCategoriesFound(props.categoriesFound || [])
		setProductsAndTagsAllowed(props.productsAndTagsAllowed || [])
		if (props.isBiochemicalRole) {
			handleRoleChange("sede")
		}
	}, [props])

	useEffect(() => {
		async function getLabs() {
			const token = Cookies.get("token")
			await axios
				.get(`${BACK_APP_URI}/laboratory`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(res => {
					setLabsFound(res.data.laboratories)
					setIsAdmin(res.data.userdata)
				})
		}
		getLabs()
	}, [])

	useEffect(() => {
		if (!labsAssigned.length) {
			setProductsAndTagsFromLabsAssigned([])
			return
		}
		let productsToSelect = []

		labsAssigned.forEach(lab => {
			lab.tags.forEach(productFromLab => {
				if (productsToSelect.some(product => product._id === productFromLab._id) || !productFromLab.isProducts) return

				productsToSelect.push(productFromLab)
			})
			lab.products.forEach(productFromLab => {
				if (productsToSelect.some(product => product._id === productFromLab._id)) return

				productsToSelect.push(productFromLab)
			})
		})
		setProductsAndTagsFromLabsAssigned(productsToSelect)
	}, [labsAssigned])

	const onSubmit = () => {
		try {
			const roleToSend = role
			const roleNameToSend = roleName

			// Filtra entre productos y tags
			const productsToBeAllowed = productsAndTagsAllowed.filter(product => !product.isProducts)
			const tagsToBeAllowed = productsAndTagsAllowed.filter(product => product.isProducts)

			if (props.type === "createUser") {
				axios({
					method: "post",
					url: `${BACK_APP_URI}/users/add`,
					data: {
						fname: fname,
						lname: lname,
						position: position,
						role: roleToSend,
						roleName: roleNameToSend,
						username: username,
						email: email,
						password: password,
						labs: labsAssigned,
						labOrders: labOrders,
						categoriesAssigned: categoriesAssigned,
						startDateOrder: startDate,
						endDateOrder: endDate,
						biochemicalsNotAllowed: biochemicalsNotAllowed,
						biochemicalRole: role === "sede" ? true : false,
						productsAllowed_biochemicalRole: productsToBeAllowed,
						productTagsAllowed_biochemicalRole: tagsToBeAllowed,
					},
				}).then(function (response) {
					//alert("Usuario creado correctamente");
					navigate("/users")
				})
			} else if (props.type === "editUser") {
				axios({
					method: "put",
					url: `${BACK_APP_URI}/users/edit/${props.id}`,
					data: {
						fname: fname,
						lname: lname,
						position: position,
						role: roleToSend,
						roleName: roleNameToSend,
						username: username,
						email: email,
						labs: labsAssigned,
						labOrders: labOrders,
						categoriesAssigned: categoriesAssigned,
						startDateOrder: startDate,
						endDateOrder: endDate,
						biochemicalsNotAllowed: biochemicalsNotAllowed,
						biochemicalRole: role === "sede" ? true : false,
						productsAllowed_biochemicalRole: productsToBeAllowed,
						productTagsAllowed_biochemicalRole: tagsToBeAllowed,
					},
				}).then(function (response) {
					// alert("Usuario actualizado correctamente");
					navigate("/users")
				})
			}
		} catch (error) {
			console.log(error)
		}
	}
	console.log(labOrders)
	const handleLabsAssigned = (newLabsAssigned) => {
		setLabsAssigned(newLabsAssigned)
		const newLabOrders = []

		newLabsAssigned.forEach(lab => {
			const isAlreadyAdded = labOrders.some(labOrder => labOrder.lab._id === lab._id)
			if(isAlreadyAdded) {
				newLabOrders.push(labOrders.find(labOrder => labOrder.lab._id === lab._id))
				return
			}

			newLabOrders.push({
				lab: {
					_id: lab._id,
					name: lab.name
				},
				quantityAvailable: 2,
				extraOrder: false
			})
		})

		setLabOrders(newLabOrders)
	}

	const handleRoleChange = role => {
		setRole(role)
		setRoleName(role)
	}
	const handleOpenExtraOrder = e => {
		setIsExtraLabOrdersOpen(prev => !prev)
	}
	const handleDateRangeChange = ({ startDate, endDate }) => {
		const newStartDate = moment.utc(startDate).utcOffset(0).startOf('day').hour(8).minute(0);
		const newEndDate = moment.utc(endDate).utcOffset(0).endOf('day').hour(8).minute(0);
	
		setStartDate(newStartDate);
		setEndDate(newEndDate);
	};

	return (
		<>
			<div className="main-container">
				<div className="header-user-add">
					<h1 className="text-user">{props.type === "createUser" ? "Registrar Usuario" : "Editar Usuario"}</h1>
					<div className="divider-user" />
					<div className="content-user-add ">
						<h1 className="title-user">Usuario</h1>
						<Form
							className="user-form"
							onSubmit={handleSubmit(onSubmit)}
						>
							<FloatingLabel
								label="Nombre"
								className="mb-3"
							>
								<Form.Control
									className="input--primary"
									placeholder="name"
									name="fname"
									value={fname}
									type="text"
									{...register("fname", {
										required: true,
										maxLength: 40,
										pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, //letras y espacios, pueden llevas acentos
									})}
									onChange={e => {
										setFname(e.target.value)
									}}
								/>
								{errors.fname?.type === "required" && <p class="error">Campo requerido </p>}
								{errors.fname?.type === "maxLength" && <p class="error">Cantidad minima de caracteres es de 40</p>}
								{errors.fname?.type === "pattern" && <p class="error">Solo puede contener letras</p>}
							</FloatingLabel>

							<FloatingLabel
								label="Apellido"
								className="mb-3"
							>
								<Form.Control
									placeholder="Apellido"
									value={lname}
									className="input--primary"
									name="lname"
									type="textarea"
									{...register("lname", {
										required: true,
										maxLength: 40,
										pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, //letras y espacios, pueden llevas acentos
									})}
									onChange={e => {
										setLname(e.target.value)
									}}
								/>
								{errors.lname?.type === "required" && <p class="error">Campo requerido </p>}
								{errors.lname?.type === "maxLength" && <p class="error">Cantidad max. de caracteres es de 40</p>}
								{errors.lname?.type === "pattern" && <p class="error">Solo puede contener letras</p>}
							</FloatingLabel>

							<FloatingLabel
								label="Cargo"
								className="mb-3"
							>
								<Form.Control
									className="input--primary"
									placeholder="Cargo"
									name="position"
									value={position}
									type="text"
									{...register("position", {
										required: false,
										maxLength: 40,
										pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, //letras y espacios, pueden llevas acentos
									})}
									onChange={e => {
										setPosition(e.target.value)
									}}
								/>
								{errors.position?.type === "maxLength" && <p class="error">Cantidad max. de caracteres es de 40</p>}
								{errors.position?.type === "pattern" && <p class="error">Solo puede contener letras</p>}
							</FloatingLabel>

							<FloatingLabel
								label="Nombre de usuario"
								className="mb-3"
							>
								<Form.Control
									placeholder="Nombre de usuario"
									className="input--primary"
									name="username"
									type="text"
									value={username}
									{...register("username", {
										required: true,
										pattern: /^[a-zA-Z0-9_-]+$/,
										minLength: 3,
									})}
									onChange={e => {
										setUsername(e.target.value)
									}}
								/>
								{errors.username?.type === "required" && <p class="error">Campo requerido </p>}
								{errors.username?.type === "pattern" && <p class="error">Caracteres inválidos</p>}
								{errors.username?.type === "minLength" && <p class="error">Cantidad minima de caracteres de 3</p>}
							</FloatingLabel>

							{props.type === "createUser" ? (
								<>
									<FloatingLabel
										label="Contraseña"
										className="mb-3"
									>
										<Form.Control
											className="input--primary"
											placeholder="Contraseña"
											name="password"
											type={isPasswordShown ? "text" : "password"}
											{...register("password", {
												required: true,
												minLength: 8,
											})}
											onChange={e => {
												setPassword(e.target.value)
											}}
										/>
										{errors.password?.type === "required" && <p class="error">Campo requerido </p>}
										{errors.password?.type === "minLength" && <p class="error">Cantidad minima de caracteres de 8</p>}
									</FloatingLabel>

									<Form.Check
										style={{ display: "flex", gap: "10px" }}
										inline
										label="Mostrar"
										checked={isPasswordShown}
										onChange={() => {
											setIsPasswordShown(prev => !prev)
										}}
									/>
								</>
							) : (
								<EditPasswordModal userId={props.id} />
							)}

							<div>
								<h3 className="register-user-text">Tipo de usuario</h3>
							</div>

							<div className="register-user">
								<Form.Group name="role">
									<Form.Check
										type="radio"
										id="admin"
										name="role"
										label="Administrador"
										{...register("role", {
											required: true,
										})}
										onChange={() => handleRoleChange("admin")}
										checked={roleName === "admin"}
									/>
									{errors.role?.type === "required" && <p class="error">Campo requerido </p>}
								</Form.Group>

								{/* <Form.Group name="role">
									<Form.Check
										type="radio"
										label="Usuario"
										{...register("role", {
											required: true,
										})}
										onChange={() => handleRoleChange("user")}
										checked={roleName === "user"}
									/>
									{errors.role?.type === "required" && <p class="error">Campo requerido </p>}
								</Form.Group> */}

								{/* <Form.Group name="role">
									<Form.Check
										type="radio"
										label="Sede"
										{...register("role", {
											required: true,
										})}
										onChange={() => handleRoleChange("sede")}
										checked={roleName === "sede"}
									/>
									{errors.role?.type === "required" && <p class="error">Campo requerido </p>}
								</Form.Group> */}

								<Form.Group name="role">
									<Form.Check
										type="radio"
										label="Usuario"
										{...register("role", {
											required: true,
										})}
										onChange={() => {
											handleRoleChange("sede")
										}}
										checked={roleName === "sede"}
									/>
									{errors.role?.type === "required" && <p class="error">Campo requerido </p>}
								</Form.Group>

								<Form.Group name="role">
									<Form.Check
										type="radio"
										label="Inventario"
										{...register("role", {
											required: true,
										})}
										onChange={() => handleRoleChange("logistic")}
										checked={roleName === "logistic"}
									/>
									{errors.role?.type === "required" && <p class="error">Campo requerido </p>}
								</Form.Group>
							</div>

							{role === "sede" && (
								<>
									<hr />

									<div className="d-flex justify-content-between">
										<p>Período de pedido</p>
										<DateRangePicker
											startDate={startDate}
											startDateId="your_unique_start_date_id"
											endDate={endDate}
											endDateId="your_unique_end_date_id"
											onDatesChange={handleDateRangeChange}
											focusedInput={focusedInput}
											onFocusChange={focusedInput => setFocusedInput(focusedInput)}
											small
											displayFormat="DD/MM/YY" // Define el formato de visualización
											monthFormat="MMMM YYYY"
											weekDayFormat="dd"
											isOutsideRange={() => {}}
										/>
									</div>

									<Form.Switch
										style={{
											display: "flex",
											gap: "10px",
											alignItems: "baseline",
											justifyContent: "space-between",
											paddingLeft: "0",
										}}
									>
										<p>Deshabilitar Bioquímicos</p>
										<Form.Check
											type="switch"
											checked={biochemicalsNotAllowed === true}
											onChange={() => setBiochemicalsNotAllowed(prev => !prev)}
											style={{ fontSize: "1.25rem" }}
										></Form.Check>
									</Form.Switch>

									{/* Buscador de categorías */}
									<BoxSelector
										allItems={categoriesFound}
										itemsAssigned={categoriesAssigned}
										title={"Seleccionar Categorías"}
										setItemsAssigned={setCategoriesAssigned}
									/>
								</>
							)}

							{(role === "sede" || role === "logistic") && (
								<>
									<BoxSelector
										allItems={labsFound}
										itemsAssigned={labsAssigned}
										title={"Seleccionar Laboratorios"}
										setItemsAssigned={handleLabsAssigned}
									/>
								</>
							)}

							{role === "sede" && (
								<>
									<Button
										style={{
											marginBottom: "16px",
										}}
										size="small"
										variant="outlined"
										onClick={handleOpenExtraOrder}
									>
										Agregar Pedido Extra
									</Button>
									<BoxSelector
										allItems={productsAndTagsFromLabsAssigned}
										itemsAssigned={productsAndTagsAllowed}
										title={"Productos Bioquímicos Habilitados"}
										setItemsAssigned={setProductsAndTagsAllowed}
									/>
								</>
							)}

							<div className="button-user">
								<button
									children="Cancelar"
									type="submit"
									className="button-edit-text"
									onClick={() => {
										navigate("/users")
									}}
								/>
								<button
									children="Guardar"
									type="submit"
									className="button-edit"
								/>
							</div>
						</Form>
					</div>
					<div />
				</div>
			</div>
			{
				<AddExtraOrderModal
					isOpen={isExtraLabOrdersOpen}
					handleModalClose={() => setIsExtraLabOrdersOpen(false)}
					labOrders={labOrders}
					setLabOrders={setLabOrders}
				/>
			}
		</>
	)
}
