import React, { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import { Card, InputGroup } from "react-bootstrap"

export default function AddExtraOrderModal({ isOpen, handleModalClose, labOrders = [], setLabOrders }) {
	const [inputValue, setInputValue] = useState("")

	return (
		<Modal
			show={isOpen}
			onHide={handleModalClose}
			backdrop="static"
		>
			<Modal.Header closeButton>
				<Modal.Title>Agregar Pedido Extra</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Card
							className="input--primary"
							style={{ width: "100%" }}
						>
							<Card.Body>
								{!labOrders.length ? (
									<div>No hay laboratorios asignados a este usuario</div>
								) : (
									<>
										<Form.Control
											className="input-primary"
											type="text"
											style={{
												marginBottom: ".5rem",
											}}
											value={inputValue}
											placeholder="Buscar Laboratorio..."
											onChange={e => setInputValue(e.target.value)}
										/>
										<div
											style={{
												maxHeight: "400px",
												overflowY: "auto",
											}}
										>
											{labOrders.map(labOrder => {
												if (!labOrder.lab.name.toUpperCase().includes(inputValue.toUpperCase())) return

												return (
													<FormGroup key={labOrder.lab.name}>
														<FormControlLabel
															control={
																<Checkbox
																	onChange={e =>
																		setLabOrders(prev => {
																			const newArr = prev.map(item => {
																				if (item.lab.name === labOrder.lab.name) {
																					return { ...item, extraOrder: e.target.checked }
																				} else {
																					return item
																				}
																			})
																			return newArr
																		})
																	}
																	checked={labOrder.extraOrder}
																/>
															}
															label={`${labOrder.lab.name}`}
														/>
													</FormGroup>
												)
											})}
										</div>
									</>
								)}
							</Card.Body>
						</Card>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={handleModalClose}
				>
					Cerrar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
