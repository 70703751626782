import React, { useState } from "react"
import { read, utils, writeFile } from "xlsx"
import Table from "react-bootstrap/esm/Table"
import axios from "axios"
import Modal from "react-bootstrap/esm/Modal"
import Button from "react-bootstrap/esm/Button"
import iconExcel from "../../ABMbotones/iconButtonAbm/iconExcel.svg"
import Form from "react-bootstrap/esm/Form"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
import { useNavigate } from "react-router-dom"
import { Nav } from "react-bootstrap"
import "../CargaViaExcel.css"
import AcceptAdditionModal from "./AcceptAdditionModal";
import AcceptReductionModal from "./AcceptReductionModal";
import AcceptEditionModal from "./AcceptEditionModal";
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { formatJSON, handleFileChange } from "../utils"
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Tooltip } from "@mui/material"



const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const CargaViaExcel = () => {
	const navigate = useNavigate()
	const [data, setData] = useState([])
	const [dataHeader, setDataHeader] = useState([])
	const [handleSelection, setHandleSelection] = useState("Ingresar")
	const [show, setShow] = useState(false)
	const [tabSection, setTabSection] = useState("Bioquimicos")
	const [idType, setIdType] = useState("Code")

	// Si los productos enviados vienen sin lotes
	const [isProductWithoutLotOperation, setIsProductWithoutLotOperation] = useState(false)

	// Suma
	const [showAcceptAdditionModal, setShowAcceptAdditionModal] = useState(false)
	const [productsToAcceptAddition, setProductsToAcceptAddition] = useState({})

	// Descontar
	const [showAcceptReductionModal, setShowAcceptReductionModal] = useState(false)
	const [productsToAcceptReduction, setProductsToAcceptReduction] = useState({})

	// Edicion
	const [showAcceptEditionModal, setShowAcceptEditionModal] = useState(false)
	const [productsToAcceptEdition, setProductsToAcceptEdition] = useState({})

	// Estados para Popup

	const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")

	// Funcion para ir a "Productos"

	function redirectPage() {
		navigate("/productos")
	}

	const handleClose = () => {
		setShow(false)
		setData([])
	}
	const handleShow = () => setShow(true)

	const generarArregloJSON = async () => {
		const jsonArr = formatJSON(data)

		try {
			if (tabSection === "Bioquimicos") {
				if (handleSelection === "Ingresar") {
					const response = await axios({
						method: "post",
						url: `${BACK_APP_URI}/productos/sumarExcel`,
						data: {
							products: jsonArr,
							idType: idType,
							isProductWithoutLotOperation: isProductWithoutLotOperation,
						},
					})

					setProductsToAcceptAddition(response.data)
					setShowAcceptAdditionModal(true)
					handleClose()
				} else if (handleSelection === "Descontar") {
					const response = await axios({
						method: "post",
						url: `${BACK_APP_URI}/productos/descontarExcel`,
						data: {
							products: jsonArr,
							idType: idType,
						},
					})

					setProductsToAcceptReduction(response.data)
					setShowAcceptReductionModal(true)
					handleClose()
				} else if (handleSelection === "Editar") {
					const response = await axios({
						method: "post",
						url: `${BACK_APP_URI}/productos/editarExcel`,
						data: {
							products: jsonArr,
							idType: idType,
						},
					})

					setProductsToAcceptEdition(response.data)
					setShowAcceptEditionModal(true)
					handleClose()
				}
			} else {
				if (handleSelection === "Ingresar") {
					const response = await axios({
						method: "post",
						url: `${BACK_APP_URI}/otros_productos/sumarExcel`,
						data: {
							products: jsonArr,
						},
					})

					setProductsToAcceptAddition(response.data)
					setShowAcceptAdditionModal(true)
					handleClose()
				} else if (handleSelection === "Descontar") {
					const response = await axios({
						method: "post",
						url: `${BACK_APP_URI}/otros_productos/descontarExcel`,
						data: {
							products: jsonArr,
						},
					})

					setProductsToAcceptReduction(response.data)
					setShowAcceptReductionModal(true)
					handleClose()
				} else if (handleSelection === "Editar") {
					const response = await axios({
						method: "post",
						url: `${BACK_APP_URI}/otros_productos/editarExcel`,
						data: {
							products: jsonArr,
						},
					})

					setProductsToAcceptEdition(response.data)
					setShowAcceptEditionModal(true)
					handleClose()
				}
			}
		} catch (error) {
			console.error(`Error updating data:`, error)
		}
	}

	const handleTabChange = section => {
		if (section === "Bioquimicos") {
			setHandleSelection("Ingresar")
		} else {
			setHandleSelection("Nuevos")
		}
		setProductsToAcceptAddition([])
		setProductsToAcceptReduction([])
		setProductsToAcceptEdition([])
		setTabSection(section)
	}

	const handleSuccessfulMessage = msg => {
		setVariable(true)
		setErrorBool(false)
		setAvisomsg(msg)
	}

	const handleErrorMessage = msg => {
		setVariable(true)
		setErrorBool(true)
		setAvisomsg(msg)
	}

	const downloadTemplate = async () => {
		try {

			const urlName = tabSection === "Bioquimicos" ? (isProductWithoutLotOperation ? "biochemicalWithoutLot" : "biochemicalWithLot") : "otherProducts"
			const fileName = tabSection === "Bioquimicos" ? (isProductWithoutLotOperation ? "Plantilla_Bioquimicos_Sin_Lotes" : "Plantilla_Bioquimicos") : "Plantilla_Varios"

			const response = await axios.get(`${BACK_APP_URI}/descargarPlantilla/${urlName}`, {
				responseType: 'arraybuffer',
				timeout: 30000,
				headers: {
					'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				},
				responseType: 'arraybuffer'
			});
	
			const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
	
			const urlBlob = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = urlBlob;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			URL.revokeObjectURL(urlBlob);
		} catch (error) {
			console.error('Error downloading Excel file:', error.response || error.message);
		}
	}


    return (
        <>
            <Button className="btn-camera" onClick={handleShow}>
                <PostAddOutlinedIcon
                />
            </Button>

			<AcceptAdditionModal
				isProductWithoutLotOperation={isProductWithoutLotOperation}
				handleClose={() => setShowAcceptAdditionModal(false)}
				show={showAcceptAdditionModal}
				productsToAcceptAddition={productsToAcceptAddition}
				handleSuccessfulMessage={handleSuccessfulMessage}
				handleErrorMessage={handleErrorMessage}
				tabSection={tabSection}
			/>
			<AcceptReductionModal
				handleClose={() => setShowAcceptReductionModal(false)}
				show={showAcceptReductionModal}
				productsToAcceptReduction={productsToAcceptReduction}
				handleSuccessfulMessage={handleSuccessfulMessage}
				handleErrorMessage={handleErrorMessage}
				tabSection={tabSection}
			/>
			<AcceptEditionModal
				handleClose={() => setShowAcceptEditionModal(false)}
				show={showAcceptEditionModal}
				productsToAcceptEdition={productsToAcceptEdition}
				handleSuccessfulMessage={handleSuccessfulMessage}
				handleErrorMessage={handleErrorMessage}
				tabSection={tabSection}
			/>

			<Modal
				size="lg"
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<div className="modal-container">
					<Modal.Title className="modal-title-excel">Carga Mediante Excel</Modal.Title>
					<div className="btn-tab-modal">
						<Nav
							className="nav-cont-stock nav-tabs"
							defaultActiveKey={tabSection}
						>
							<Nav.Item>
								<Nav.Link
									eventKey="biochemicals"
									onClick={() => handleTabChange("Bioquimicos")}
									active={tabSection === "Bioquimicos"}
								>
									Bioquímicos
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									eventKey="Varios"
									onClick={() => handleTabChange("Varios")}
									active={tabSection === "Varios"}
								>
									Varios
								</Nav.Link>
							</Nav.Item>
						</Nav>
					</div>
					<Modal.Header>
						<Form.Group>
							{tabSection === "Bioquimicos" && (
								<div className="container-check-modal">
									<div className="check-modal-row">
										<Form.Check
											className="check-modal-excel"
											inline
											label="Ingresar"
											checked={handleSelection === "Ingresar"}
											onChange={() => {
												setHandleSelection("Ingresar")
											}}
										/>
										<Form.Check
											className="check-modal-excel"
											inline
											label="Descontar"
											checked={handleSelection === "Descontar"}
											onChange={() => {
												setHandleSelection("Descontar")
											}}
										/>
									</div>
									<div className="check-modal-row">
										<Form.Check
											className="check-modal-excel"
											inline
											label="Editar"
											checked={handleSelection === "Editar"}
											onChange={() => {
												setHandleSelection("Editar")
											}}
										/>

										<Form.Check
											inline
											label="Código"
											checked={idType === "Code"}
											onChange={() => {
												setIdType("Code")
											}}
											className="custom-checkbox"
										/>
									</div>
									<div className="check-modal-row">
										<Form.Check
											inline
											label="Nbu"
											checked={idType === "Nbu"}
											onChange={() => {
												setIdType("Nbu")
											}}
											className="custom-checkbox"
										/>

										{handleSelection === "Ingresar" && (
											<>
												<Form.Check
													inline
													type="switch"
													label="Solo producto base"
													checked={isProductWithoutLotOperation}
													onChange={() => {
														setIsProductWithoutLotOperation(prev => !prev)
													}}
													className="custom-checkbox"
												/>
											</>
										)}
									</div>
								</div>
							)}
							{tabSection === "Varios" && (
								<>
									<Form.Check
										inline
										label="Ingresar"
										checked={handleSelection === "Ingresar"}
										onChange={() => {
											setHandleSelection("Ingresar")
										}}
									/>
									<Form.Check
										inline
										label="Descontar"
										checked={handleSelection === "Descontar"}
										onChange={() => {
											setHandleSelection("Descontar")
										}}
									/>

									<Form.Check
										inline
										label="Editar"
										checked={handleSelection === "Editar"}
										onChange={() => {
											setHandleSelection("Editar")
										}}
									/>
								</>
							)}
						</Form.Group>
					</Modal.Header>
					<Modal.Body>
						<div>
							<Form.Group
								controlId="formFile"
								className="mb-3"
								style={{
									display: "flex",
									flexWrap: "nowrap",
									justifyContent: "center",
									alignItems: "center",
									gap: "6px"
								}}
							>
								<Form.Control
									className="input-modal"
									type="file"
									onChange={e => {
										handleFileChange(e, setData, setDataHeader)
									}}
								/>
								<Button onClick={downloadTemplate}>
									<Tooltip title="Descargar plantilla">
										<FileDownloadIcon />
									</Tooltip>
									</Button>
							</Form.Group>
						</div>
						{data.length > 0 ? (
							<>
								<div className="header-table-modal">
									<h2>Productos</h2>
									<div className="">
										<Table
											className=" table-striped table-bordered table-hover"
											responsive
										>
											<thead>
												<tr>
													{dataHeader.map((item, index) => (
														<th key={index}>{item}</th>
													))}
												</tr>
											</thead>
											<tbody>
												{data.slice(1).map((rowData, rowIndex) => (
													<tr key={rowIndex}>
														{rowData.map((cellData, cellIndex) => (
															<td key={cellIndex}>{cellData}</td>
														))}
													</tr>
												))}
											</tbody>
										</Table>
									</div>
								</div>
							</>
						) : null}
						<Modal.Footer>
							<div className="d-flex">
								{data.length > 0 ? (
									<Button
										style={{ width: "10rem", marginRight: "1rem" }}
										className="btn--primary"
										variant="primary"
										onClick={generarArregloJSON}
									>
										Cargar
									</Button>
								) : null}

								<Button
									variant="secondary"
									onClick={handleClose}
								>
									Cerrar
								</Button>
							</div>
						</Modal.Footer>
					</Modal.Body>
				</div>
			</Modal>

			{/* Se agrega el pop-up */}
			{variable ? (
				<AnimacionSuccessful
					errorBool={errorBool}
					avisomsg={avisomsg}
					refreshPage={redirectPage}
				/>
			) : (
				<></>
			)}
		</>
	)
}

export default CargaViaExcel
